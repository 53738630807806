//Package Management

import { Button, Input, Select, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from "@chakra-ui/react";
import {
    Box, Switch, Table, Thead, Tbody, Tr, Th, Td,
    VStack,
    FormControl,
    FormLabel,
    Tag,
    TagLabel,
    TagCloseButton,
    HStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import FetchWithToken from "../utils/FetchWithToken";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const FeatureTable = ({ features, onUpdateCount, onToggleEnabled }) => {

    const sourceOptions = [
        { value: 'URL', label: 'URL' },
        { value: 'DOC', label: 'DOC' },
        { value: 'SITEMAP', label: 'SITEMAP' },
        { value: 'Q&A', label: 'Q&A' },
        { value: 'RSS FEED', label: 'RSS FEED' },
        { value: 'URL LIST', label: 'URL LIST' },
    ];
    const [selectedOptions, setSelectedOptions] = useState(features.SOURCES);

    const handleCountUpdate = (feature, count) => {
        onUpdateCount(feature, count);
    };

    const handleChangeEnabled = (feature, isChecked) => {
        onToggleEnabled(feature, isChecked);
    };
    const handleSourceChange = (feature, event) => {
        // onUpdateCount(feature, selectedSources);
        const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
        const uniqueSelectedValues = selectedValues.filter(
            (value) => !selectedOptions.includes(value)
        );
        const updatedSelectedOptions = [...selectedOptions, ...uniqueSelectedValues];
        setSelectedOptions(updatedSelectedOptions);
        onUpdateCount(feature, updatedSelectedOptions)
    };

    const handleRemoveOption = (feature, value) => {
        const updatedOptions = selectedOptions.filter((option) => option !== value);
        setSelectedOptions(updatedOptions);
        onUpdateCount(feature, updatedOptions)
    };

    return (
        <Table variant="striped" colorScheme="orange" size="sm" fontSize={"sm"}>
            <Thead>
                <Tr>
                    <Th>Feature</Th>
                    <Th>Enabled</Th>
                    <Th>Count</Th>
                </Tr>
            </Thead>
            <Tbody>
                {Object.entries(features).map(([feature, data]) => (
                    <Tr key={feature} >
                        <Td pt={1} pb={1}>{feature}</Td>
                        <Td pt={1} pb={1}>
                            {feature === 'SOURCES' ? ('') :
                                <Switch
                                    colorScheme={'orange'}
                                    isChecked={data.ENABLED}
                                    onChange={(e) => handleChangeEnabled(feature, e.target.checked)}
                                />}
                        </Td>
                        <Td pt={1} pb={1}>
                            {feature === 'SOURCES' ? (
                                <Box>
                                    <Select
                                        bg={'white'}
                                        onChange={(event) => handleSourceChange(feature, event)}
                                    >
                                        {sourceOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}

                                    </Select>
                                    <Box pt={1} display={'flex'} gap={1}>
                                        {selectedOptions.map((option) => (
                                            <Tag key={option} variant="subtle" colorScheme="teal">
                                                <TagLabel>{option}</TagLabel>
                                                <TagCloseButton onClick={() => handleRemoveOption(feature, option)} />
                                            </Tag>
                                        ))}
                                    </Box>
                                </Box>
                            ) :
                            feature === 'WHATSAPP_INTEGRATIONS'? ("") 
                            : (
                                <Box>
                                    <Input
                                        bg={'white'}
                                        type="number"
                                        required
                                        value={data.count}
                                        onChange={(e) => handleCountUpdate(feature, parseInt(e.target.value))}
                                    />
                                </Box>
                            )}
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

const PlanComponent = ({ updatedPlanCall, planName, planDetail }) => {
    const toast = useToast()
    const [features, setFeatures] = useState(planDetail);

    const handleCountUpdate = (feature, count) => {
        if (feature == 'SOURCES') {
            setFeatures((prevFeatures) => ({
                ...prevFeatures,
                [feature]:
                    count,
            }));
        }
        else {
            setFeatures((prevFeatures) => ({
                ...prevFeatures,
                [feature]: {
                    ...prevFeatures[feature],
                    count: count,
                },
            }));
        }
    };

    const handleToggleEnabled = (feature, isChecked) => {
        setFeatures((prevFeatures) => ({
            ...prevFeatures,
            [feature]: {
                ...prevFeatures[feature],
                ENABLED: isChecked,
            },
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            plan: planName,
            package_data: features
        }

        FetchWithToken(`${BASE_URL}/packages/update`, 'PUT', data)
            .then(data => {
                if (data) {
                    toast({
                        title: 'Data Updated Successfully',
                        status: 'warning',
                        duration: 4000,
                        isClosable: true,
                    })
                }
                updatedPlanCall()
            }).catch(e => {
                toast({
                    title: 'Failed To Update The Packages',
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                })
            })
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box p={4}>
                <FeatureTable
                    features={features}
                    onUpdateCount={handleCountUpdate}
                    onToggleEnabled={handleToggleEnabled}
                />
                <Button type="submit" mt={2} bg={'var(--Web-color-orange)'} color={'white'} _hover={{ bg: "#EB6F0D" }} >Save</Button>
            </Box>
        </form>
    );
};




const PackageModify = () => {

    const [planDetails, setPlanDetails] = useState();
    const [planNames, setplanNames] = useState(null);

    const [selectedPlanName, setSelectedPlanName] = useState();
    const handleTabChange = (index) => {
        setSelectedPlanName(planNames[index]);
    };

    const fetchPackageDetails = async () => {
        FetchWithToken(`${BASE_URL}/packages?plan=All`, 'GET')
            .then(data => {
                setPlanDetails(Object.values(data.package_data))
                setplanNames(Object.keys(data.package_data))
                setSelectedPlanName(Object.keys(data.package_data)[0])
            });
    }

    const updatedPlan = async () => {
        fetchPackageDetails()
    }

    useEffect(() => {
        fetchPackageDetails()
    }, []);

    return (
        <>
            {planNames ?
                <Tabs isLazy onChange={handleTabChange}>
                    <TabList>
                        {planNames && planNames.map((planName) => (
                            <Tab key={planName}>{planName}</Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        {planDetails && planDetails.map((planDetail) => (
                            <TabPanel key={planDetail}>
                                <PlanComponent updatedPlanCall={updatedPlan} planName={selectedPlanName} planDetail={planDetail} />
                            </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
                :
                <Box display="flex" alignItems="center" justifyContent="center" mt={10}>
                    <Spinner color="blue.500" />
                </Box>
            }
        </>
    );
};

export default PackageModify;