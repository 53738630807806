import { useCallback, useEffect, useState } from "react";
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useTranslation } from "react-i18next";
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Divider, Image, SimpleGrid, Text} from "@chakra-ui/react";
import WhatsAppIntegration from "./Integration_components/WhatsAppIntegration";
import WhatsAPPIcon from "../assets/integration/whatsapp.svg";
import SlackIcon from "../assets/integration/slack.svg";
import MessengerIcon from "../assets/integration/messenger.svg";
import TelegramIcon from "../assets/integration/telegram.svg";
import withAccessControl from "../utils/withAccessControl";
import { PricingChartModel } from "./PricingChartModel";
import FetchWithToken from "../utils/FetchWithToken";
  
const Integration = ({ hasAccess, handleCallback }) => {
    const { t, i18n } = useTranslation();
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [allBotsData, setAllBotsData] = useState([]);

    const selectedTeam = JSON.parse(localStorage.getItem("docsbot-users")).selectedTeam;
    
    const handleWhatsAppOpenModal = () => {
        (hasAccess?.WHATSAPP_INTEGRATIONS.enabled) ? setShowWhatsAppModal(true) : setShowPricingModal(true);
    };

    const handleWhatsAppCloseModal = () => {
        setShowWhatsAppModal(false);
    };

    const handleClosePricingModal = () => {
        setShowPricingModal(false);
    };

    const fetchData = useCallback(() => {
        FetchWithToken(BASE_URL + `/chatbots?team_id=${selectedTeam.team_id}`, 'GET')
            .then(data => {
                setAllBotsData(data.bots);
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box bg={"#FFFFFF"} borderRadius={"10px"} height={"100%"}>
            <Box p={"10px 20px"}>
                <Text as={"b"}>{t("Integrations.Connected Apps")}</Text>
                <Text mt={1} fontSize='md'>{t("Integrations.Integrate with your favorite apps and supercharge your workflow")}</Text>
                <Divider mt={3} />
                <SimpleGrid paddingTop={5} spacing={6} templateColumns="repeat(auto-fill, minmax(250px, 1fr))">
                    <Card p={"20px 25px"} shadow={0} variant={"outline"}>
                        <CardHeader p={0} display="flex" justifyContent="space-between">
                            <Image src={WhatsAPPIcon} alt="BotIcon" />
                        </CardHeader>
                        <CardBody m={"20px 0 10px 0"} p={0} display="flex" flexDirection="column" alignItems="start" gap={2}>
                            <Text fontWeight={700}>{t("Integrations.WhatsApp")}</Text>
                            <Text fontSize={13} fontWeight={500} color="gray.500">
                                {t("Integrations.Connect with billions of users on the world's by integrating with WhatsApp")}
                            </Text>
                        </CardBody>
                        <CardFooter p={0}>
                            <Button onClick={() => handleWhatsAppOpenModal()} bg={""} w={"100%"} h={"34px"} border={"1px solid rgba(33, 33, 33, 0.21)"} _hover={{ bg: "var(--Web-color-orange)", color: "white" }} rightIcon={<MdOutlineKeyboardArrowRight />} >
                                {t("Integrations.Connect")}
                            </Button>
                        </CardFooter>
                    </Card>

                    <Card p={"20px 25px"} shadow={0} variant={"outline"}>
                        <CardHeader p={0} display="flex" justifyContent="space-between">
                            <Image src={SlackIcon} alt="BotIcon" />
                        </CardHeader>
                        <CardBody m={"20px 0 10px 0"} p={0} display="flex" flexDirection="column" alignItems="start" gap={2}>
                            <Text fontWeight={700}>{t("Integrations.Slack")}</Text>
                            <Text fontSize={13} fontWeight={500} color="gray.500">
                                {t("Integrations.Supercharge your team's productivity by integrating our chatbot into Slack")}
                            </Text>
                        </CardBody>
                        <CardFooter p={0}>
                            <Button bg={""} w={"100%"} h={"34px"} border={"1px solid rgba(33, 33, 33, 0.21)"} isDisabled _hover={{ bg: "var(--Web-color-orange)", color: "white" }} >
                                {t("Integrations.Coming Soon")}
                            </Button>
                        </CardFooter>
                    </Card>

                    <Card p={"20px 25px"} shadow={0} variant={"outline"}>
                        <CardHeader p={0} display="flex" justifyContent="space-between">
                            <Image src={MessengerIcon} alt="BotIcon" />
                        </CardHeader>
                        <CardBody m={"20px 0 10px 0"} p={0} display="flex" flexDirection="column" alignItems="start" gap={2}>
                            <Text fontWeight={700}>{t("Integrations.Messenger")}</Text>
                            <Text fontSize={13} fontWeight={500} color="gray.500">
                                {t("Integrations.Unlock the power of Messenger by integrating our advanced chatbot")}
                            </Text>
                        </CardBody>
                        <CardFooter p={0}>
                            <Button bg={""} w={"100%"} h={"34px"} border={"1px solid rgba(33, 33, 33, 0.21)"} isDisabled _hover={{ bg: "var(--Web-color-orange)", color: "white" }} >
                                {t("Integrations.Coming Soon")}
                            </Button>
                        </CardFooter>
                    </Card>

                    <Card p={"20px 25px"} shadow={0} variant={"outline"}>
                        <CardHeader p={0} display="flex" justifyContent="space-between">
                            <Image src={TelegramIcon} alt="BotIcon" />
                        </CardHeader>
                        <CardBody m={"20px 0 10px 0"} p={0} display="flex" flexDirection="column" alignItems="start" gap={2}>
                            <Text fontWeight={700}>{t("Integrations.Telegram")}</Text>
                            <Text fontSize={13} fontWeight={500} color="gray.500">
                                {t("Integrations.Extend your services to Telegram users with our chatbot integration")}
                            </Text>
                        </CardBody>
                        <CardFooter p={0}>
                            <Button bg={""} w={"100%"} h={"34px"} border={"1px solid rgba(33, 33, 33, 0.21)"} isDisabled _hover={{ bg: "var(--Web-color-orange)", color: "white" }} >
                                {t("Integrations.Coming Soon")}
                            </Button>
                        </CardFooter>
                    </Card>
                </SimpleGrid>
                <WhatsAppIntegration isOpen={showWhatsAppModal} onClose={handleWhatsAppCloseModal} allBotsData={allBotsData} handleCallback={handleCallback} />
            </Box>

            {
                showPricingModal && <PricingChartModel isOpen={showPricingModal} onClose={handleClosePricingModal}/>
            }
        </Box>
    );
};

const IntegartionsWithAccessControl = withAccessControl(Integration);
export default IntegartionsWithAccessControl