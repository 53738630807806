import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiBookBookmark, BiSupport } from "react-icons/bi";
import { BsFillChatFill, BsQuestionLg } from "react-icons/bs";
import { FaRobot } from "react-icons/fa";
import { IoIosChatbubbles } from "react-icons/io";
import { RiSendPlane2Fill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import FetchWithToken from "../utils/FetchWithToken";
import { IoClose } from "react-icons/io5";

const LeadForm = ({
  LeadsData,
  sessionId,
  botid,
  handleClose,
  setLeadsFlag,
  setFormSubmitted,
  formSubmitted,
}) => {
  const toast = useToast();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [step, setStep] = useState(0);
  const [fields, setFields] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const initialFields = [];

    if (LeadsData) {
      if (LeadsData["name"]) {
        initialFields.push({ label: "Name", value: "" });
      }
      if (LeadsData["email"]) {
        initialFields.push({ label: "Email", value: "" });
      }
      if (LeadsData["phone"]) {
        initialFields.push({ label: "Phone", value: "" });
      }
    }

    setFields(initialFields);
  }, [LeadsData]);

  const toastIdRef = React.useRef();
  function closeToast() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  const showToast = (description) => {
    toastIdRef.current = toast({
      isUsingPortal: true,
      position: "bottom",
      render: () => (
        <Flex
          gap={2}
          justifyContent="space-between"
          borderRadius="10px"
          color="white"
          p={3}
          bg="blackAlpha.700"
          zIndex={9999}
        >
          <Box display="flex" gap={2}>
            <Box>
              <Text color={"whiteAlpha.700"} fontSize={"15px"} fontWeight={500}>
                {description}
              </Text>
            </Box>
          </Box>

          <IoClose
            onClick={closeToast}
            color={"RGBA(255, 255, 255, 0.60)"}
            size={20}
            cursor={"pointer"}
          />
        </Flex>
      ),
    });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleInputChange = (value, index) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field, i) => {
        if (i === index) {
          const isPhoneField = field.label.toLowerCase().includes('phone');
          let intValue = isPhoneField ? parseInt(value.replace(/\D/g, ''), 10) : value;
          if (isPhoneField) {
            intValue = isNaN(intValue) ? '' : Math.abs(intValue).toString().slice(0, 10);
          }
  
          return { ...field, value: intValue };
        } else {
          return field;
        }
      });
      return updatedFields;
    });
  };
  

  const handleNext = () => {
    const currentField = fields[step];
    if (currentField) {
      const fieldValue = currentField?.value;
      const fieldLabel = currentField?.label;

      if (fieldLabel === "Email" && fieldValue == "") {
        return;
      }
      if (fieldLabel === "Email" && !validateEmail(fieldValue)) {
        setTimeout(() => {
          showToast("Enter a valid email address");
        }, 2000);
        return;
      }
      if (fieldValue == "") {
        return;
      }
      if (step < fields?.length - 1) {
        setStep((prevStep) => prevStep + 1);
      } else if (step === fields?.length - 1) {
        handleSubmit();
      }
    }
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: 700,
    fontSize: "13px",
    fontFamily: "Satoshi, sans-serif",
  };

  const handleSubmit = () => {
    if (step + 1 === fields.length) {
      const nameField = fields.find((field) => field.label === "Name");
      const emailField = fields.find((field) => field.label === "Email");
      const phoneField = fields.find((field) => field.label === "Phone");

      if (nameField && nameField?.value == "") {
        return;
      }
      if (emailField && emailField?.value == "") {
        return;
      }
      if (emailField && !validateEmail(emailField?.value)) {
        setTimeout(() => {
          showToast("Enter valid email address");
        }, 2000);
        return;
      }
      if (phoneField && phoneField?.value == "") {
        return;
      }

      const formData = {
        name: nameField?.value || "",
        email: emailField?.value || "",
        phone: phoneField?.value || "",
        session_id: sessionId,
        bot_id: botid,
      };
      setisLoading(true);
      FetchWithToken(BASE_URL + "/add-leads", "POST", formData).then((data) => {
        console.log(data);
        setisLoading(false);
        setFormSubmitted(true);
      });
    }
  };
  return (
    <Box
      backgroundColor="white"
      width={{ base: "80%", md: "50%" }}
      display="flex"
      flexDirection="column"
      padding="10px"
      borderRadius="12.48px"
    >
      <Flex justifyContent="flex-end" width="100%">
        <CloseButton
          onClick={handleClose}
          position="justify-end"
          mt="1"
          mr="1"
          size="sm"
          colorScheme="blue"
        />
      </Flex>
      <Text
        style={{
          fontWeight: 700,
          fontSize: "13px",
          fontFamily: "Satoshi, sans-serif",
        }}
      >
        {LeadsData && LeadsData["lead_id"]
          ? LeadsData["message"]
          : "No message available"}
      </Text>

      {!formSubmitted ? (
        <>
          {fields?.slice(0, step + 1).map((field, index) => (
            <FormControl key={index}>
              <FormLabel style={labelStyle}>{field?.label}</FormLabel>
              <Input
                type="text"
                placeholder={`Enter ${field?.label}`}
                value={field?.value}
                _focus={{ outline: "none", boxShadow: "0 0 0 2px #FF8321" }}
                onChange={(e) => handleInputChange(e.target.value, index)}
                onFocus={(e) => (e.target.style.borderColor = "#FF8321")}
                onBlur={(e) => (e.target.style.borderColor = "")}
              />
            </FormControl>
          ))}

          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "10px" }}
          >
            <Text
              style={{
                fontFamily: "Satoshi, sans-serif",
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              {step + 1} of {fields?.length}
            </Text>
            <Button
                w={"70px"}
                h={"33px"}
                fontSize={"14px"}
                _hover={{ bg: "#f97913" }}
                isLoading={isLoading}
                type="submit"
                borderRadius={"65px"}
                variant="solid"
                bg={"var(--Web-color-orange)"}
                color={"white"}
              onClick={step === fields?.length - 1 ? handleSubmit : handleNext}
            >
              {step === fields?.length - 1 ? "Submit" : "Next"}
            </Button>
          </Flex>
        </>
      ) : (
        <Flex flexDirection="column" alignItems="center">
          <Flex
            width="20px"
            height="20px"
            borderRadius="50%"
            backgroundColor="#4CB71A"
            justifyContent="center"
            alignItems="center"
            margin="20px auto 8px auto"
            position="relative"
          >
            <svg
              width="12"
              height="6"
              viewBox="0 0 6 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.878906 1.81641L2.53125 3.46875L5.79297 0.207031"
                stroke="white"
                strokeWidth="1.7"
              />
            </svg>
          </Flex>
          <Text
            textAlign="center"
            fontWeight="700"
            color="#4CB71A"
            fontSize="15px"
          >
            Form submitted successfully!
          </Text>
        </Flex>
      )}
    </Box>
  );
};

const ShareChatBot = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { type, teamid, botid } = useParams();
  const toast = useToast();

  const [askInputData, setaskInputData] = useState("");
  const [firstQuestion, setFirstQuestion] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [widgetData, setWidgetData] = useState("");
  const [isLoading, setisLoading] = useState(false);
  // const [eventData, setEventData] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [leadsFlag, setLeadsFlag] = useState(false);
  const [messageCount, setMessageCount] = useState(1);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [eventover,setEventOver] = useState(false);
  

  useEffect(() => {
    if (leadsData &&  leadsData["collect_leads_at_start"] && !formSubmitted) {
      setLeadsFlag(true);
    }
  }, [leadsData, formSubmitted]);

  const handleLeadsCall = () => {
    if (leadsData && !leadsFlag && !formSubmitted) {
      if (messageCount === parseInt(leadsData["collect_leads_after_message"])) {
        setLeadsFlag(true);
      }
    }
  };
  const sessionId = localStorage.getItem("sessionId");

  const handleClose = () => {
    if (formSubmitted) {
      setLeadsFlag(false);
    }
  };

  const handleMessageSubmit = async () => {
    if(leadsFlag){
        setLeadsFlag(!leadsFlag)
    }
    setMessageCount(messageCount + 1);
    if (askInputData) {
      setChatMessages((prevChatMessages) => [
        ...prevChatMessages,
        { text: askInputData, sender: "user" },
      ]);
      handleLeadsCall();
      setisLoading(true);
      try {
        // const askData = {
        //     user_id: teamid,
        //     query: askInputData,
        //     bot_id: botid,
        //     role: type,
        //     session_id: sessionId
        // }
        setaskInputData("");
        // const response = await fetch(BASE_URL + '/chatbot/ask_question', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(askData)
        // });

        // const data = await response.json();
        // setisLoading(false)
        // setChatMessages(prevChatMessages => [
        //     ...prevChatMessages,
        //     { text: data.message, sender: 'bot' }
        // ]);
        const eventSource = new EventSource(
          BASE_URL +
            "/chatbot/ask_question?user_id=" +
            teamid +
            "&query=" +
            askInputData +
            "&bot_id=" +
            botid +
            "&role=" +
            type +
            "&session_id=" +
            sessionId
        );
        // let accumulatedData = '';
        let messageBoxLength = 0;
        eventSource.addEventListener("open", (event) => {
          setisLoading(false);
          setChatMessages((prevChatMessages) => [
            ...prevChatMessages,
            { text: "", sender: "bot" },
          ]);
          messageBoxLength = chatMessages?.length + 1;
        });

        eventSource.addEventListener("message", (event) => {
          setEventOver(true);
          const newData = event.data;
          const validJsonString = newData.replace(/'([^']+)'/g, '"$1"');
          let valueOfWe = "";
          try {
            const jsonData = JSON.parse(validJsonString);
            valueOfWe = jsonData.data;
            // Use the parsed data as needed
          } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle the error or log the problematic data for investigation
          }

          if (valueOfWe === "###finish###") {
            setEventOver(false);
            // setEventData([]);
            eventSource.close();
            // accumulatedData = '';

            if (widgetData["citations"] === "true") {
              FetchWithToken(
                BASE_URL + "/chatbot/citation?session_id=" + sessionId,
                "GET"
              ).then((data) => {
                setChatMessages((prevChatMessages) => {
                  const updatedChatMessages = [...prevChatMessages];
                  if (updatedChatMessages?.length >= messageBoxLength) {
                    updatedChatMessages[messageBoxLength] = {
                      ...updatedChatMessages[messageBoxLength],
                      source: data.source_documents,
                    };
                  }
                  return updatedChatMessages;
                });
              });
            }
            return;
          }
          // setEventData((prevData) => [...prevData, valueOfWe]);
          // accumulatedData += valueOfWe
          setChatMessages((prevChatMessages) => {
            const updatedChatMessages = [...prevChatMessages];
            if (updatedChatMessages?.length >= messageBoxLength) {
              updatedChatMessages[messageBoxLength] = {
                ...updatedChatMessages[messageBoxLength],
                text: updatedChatMessages[messageBoxLength]?.text + valueOfWe,
              };
            }
            return updatedChatMessages;
          });
        });

        eventSource.addEventListener("error", (event) => {
          // setEventData([]);
          eventSource.close();
          // accumulatedData = '';
          console.log("Error:", event);
        });
      } catch (error) {
        setisLoading(false);
        toast({
          title: "An error occurred. Please try again later.",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
        console.log("Error:", error);
      }
    }
  };

  const [showItems, setShowItems] = useState(false);

  const toggleItems = () => {
    setShowItems((prevShowItems) => !prevShowItems);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleMessageSubmit();
    }
  };

  const handleFirstQuestion = (question) => {
    setaskInputData(question);
  };

  const iconMap = {
    BsFillChatFill: BsFillChatFill,
    IoIosChatbubbles: IoIosChatbubbles,
    FaRobot: FaRobot,
    BiSupport: BiSupport,
    BiBookBookmark: BiBookBookmark,
    BsQuestionLg: BsQuestionLg,
  };

  function IconComponent({ iconName }) {
    const Icon = iconMap[iconName];
    return Icon ? <Icon size={"25px"} /> : null;
  }

  useEffect(() => {
    fetch(BASE_URL + "/chatbot/questions?bot_id=" + botid)
      .then((response) => response.json())
      .then((data) => {
        setFirstQuestion(data.quesions);
      })
      .catch((err) => console.log(err));

    FetchWithToken(BASE_URL + "/get-bot-leads?bot_id=" + botid, "GET").then(
      (data) => {
        setLeadsData(data?.settings[0]);
      }
    );
  }, []);

  useEffect(() => {
    const generateSessionId = () => {
      const randomString = Math.random().toString(36).substring(2, 8); // Generate a random string of length 6
      const timestamp = Date.now().toString(36); // Convert the current timestamp to a base-36 string
      return randomString + timestamp;
    };

    const session_Id = generateSessionId();
    localStorage.setItem("sessionId", session_Id);
  }, []);

  useEffect(() => {
    fetch(BASE_URL + "/widget?bot_id=" + botid)
      .then((response) => response.json())
      .then((data) => {
        setWidgetData(data?.widget);
      })
      .catch((err) => console.log(err));
  }, []);

  const isdisable = leadsFlag ? (formSubmitted ? false : true) : false;

  return (
    <Flex
      justify={"center"}
      align={"center"}
      flexDirection={"column"}
      height={"100vh"}
    >
      <Box
        w={"100%"}
        height={"100%"}
        bg="#E7EBF1"
        border={"1px solid gray"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"flex-end"}
      >
        <Box
          display={"flex"}
          gap={4}
          bg={widgetData["header-color"]}
          p={"5px 10px"}
        >
          {widgetData["bot-avatar"] !== "None" ? (
            <Avatar
              bg={"white"}
              color={"gray.400"}
              padding={2.5}
              position="relative"
              icon={<IconComponent iconName={widgetData["bot-avatar"]} />}
            >
              <AvatarBadge
                boxSize="0.6em"
                bg="green.500"
                position="absolute"
                top="90%"
                right="1"
                transform="translateY(-50%)"
              />
            </Avatar>
          ) : (
            <></>
          )}
          <Box>
            <Heading color={"white"} fontSize="28px" size="sm">
              {widgetData["heading"]}
            </Heading>
            <Text fontSize={"13px"} fontWeight={400} ml="1" color={"white"}>
              {widgetData["subheading"]}
            </Text>
          </Box>
        </Box>
        <Box
          height={"100%"}
          overflow={"auto"}
          p={4}
          pb={2}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Box>
            {widgetData["first-message"] && (
              <Box
                width={"fit-content"}
                maxW={"650px"}
                bg={widgetData["bot-background-color"]}
                borderRadius={"14px 14px 14px 0px"}
                p={2}
                mb={2}
                ml={0}
                alignSelf={"flex-end"}
                color={widgetData["bot-text-color"]}
              >
                <p>{widgetData["first-message"]}</p>
              </Box>
            )}
            {leadsData && leadsFlag && leadsData["collect_leads_at_start"] && (
              <>
                <LeadForm
                  LeadsData={leadsData}
                  sessionId={sessionId}
                  setFormSubmitted={setFormSubmitted}
                  botid={botid}
                  setLeadsFlag={setLeadsFlag}
                  handleClose={handleClose}
                  formSubmitted={formSubmitted}
                />
              </>
            )}
            {chatMessages?.map((chat, index) => (
              <>
                {/* <Box
                                        pb={1}
                                        gap={1}
                                        display={'flex'}
                                        alignItems={'center'}
                                        fontSize={'12px'}
                                        justifyContent={chat.sender === 'user' ? 'end' : 'start'}>
                                        {chat.sender === 'bot' ? <><Avatar size={'xs'} bg="white" padding={1.5} position="relative">
                                            <img src={ChatbotLogo} alt="Chatbot Logo" />
                                        </Avatar>HappyChat AI</> : "You"}</Box> */}
                <Box
                  width={"fit-content"}
                  maxW={"650px"}
                  bg={
                    chat?.sender === "bot"
                      ? widgetData["bot-background-color"]
                      : widgetData["user-background-color"]
                  }
                  borderRadius={
                    chat?.sender === "bot"
                      ? "14px 14px 14px 0px"
                      : "14px 14px 0px 14px"
                  }
                  p={2}
                  mb={2}
                  ml={chat?.sender === "bot" ? 0 : "auto"}
                  alignSelf={chat?.sender === "user" ? "flex-start" : "flex-end"}
                  color={
                    chat?.sender === "bot"
                      ? widgetData["bot-text-color"]
                      : widgetData["user-text-color"]
                  }
                >
                  <p>
                    {chat?.text?.split("\n").map((line) => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
                  </p>
                  {chat?.source && (
                    <>
                      <div onClick={toggleItems} style={{ cursor: "pointer" }}>
                        {showItems ? "▲ Sources" : "▼ Sources"}
                      </div>
                      {showItems && (
                        <div>
                          {chat?.source?.map((item, index) => (
                            <p key={index} style={{ margin: 0 }}>
                              <a
                                style={{
                                  color: "blue",
                                  textDecoration: "none",
                                  transition: "color 0.3s",
                                }}
                                href={item}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item}
                              </a>
                            </p>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </>
            ))}
            {isLoading && (
              <Box
                width={"fit-content"}
                maxW={"430px"}
                bg={widgetData["bot-background-color"]}
                borderRadius={"0px 14px 14px 14px"}
                p={2}
                mb={2}
                ml={0}
                alignSelf={"flex-end"}
                color={widgetData["bot-text-color"]}
              >
                <BeatLoader size={6} color="black" />
              </Box>
            )}
            {leadsData &&
              leadsFlag &&
              !isLoading && !eventover &&
              !leadsData["collect_leads_at_start"] && (
                <>
                  <LeadForm
                    LeadsData={leadsData}
                    sessionId={sessionId}
                    setFormSubmitted={setFormSubmitted}
                    botid={botid}
                    setLeadsFlag={setLeadsFlag}
                    handleClose={handleClose}
                    formSubmitted={formSubmitted}
                  />
                </>
              )}
          </Box>
          <Box>
            <Box
              display={"flex"}
              gap={1}
              justifyContent={"flex-end"}
              flexDirection={{ base: "column", md: "row" }}
            >
              {!leadsFlag &&
                firstQuestion?.map((quesion, index) => (
                  <Box
                    alignItems={"center"}
                    display={chatMessages?.length > 0 ? "none" : "flex"}
                    cursor={"pointer"}
                  >
                    <Tag
                      bg={"white"}
                      width={"fit-content"}
                      maxW={"650px"}
                      borderRadius={"10px"}
                      border={"1.07379px solid #408CFD"}
                      p={2}
                      mb={2}
                      ml={"auto"}
                      alignSelf={"flex-start"}
                      onClick={() => handleFirstQuestion(quesion.question)}
                      color={"#408CFD"}
                    >
                      <Box>{quesion.question}</Box>
                    </Tag>
                  </Box>
                ))}
            </Box>
            {widgetData["support-link"] && (
              <Box display={"flex"}>
                <Tag
                  width={"fit-content"}
                  maxW={"450px"}
                  minW={"80px"}
                  textAlign={"center"}
                  borderRadius={"10px"}
                  border={"1.07379px solid gray"}
                  p={2}
                  mb={2}
                  ml={"auto"}
                  alignSelf={"flex-end"}
                  color={"gray"}
                  cursor={"pointer"}
                  onClick={() =>
                    window.open(widgetData["support-link"], "_blank")
                  }
                >
                  <Box w={"100%"} display={"flex"} justifyContent={"center"}>
                    {widgetData["support-button-text"]
                      ? widgetData["support-button-text"]
                      : widgetData["support-link"]}
                  </Box>
                </Tag>
              </Box>
            )}
          </Box>
        </Box>
        <Box borderRadius={" 0 0 12px 12px"} pt={2} pb={2}>
          <InputGroup size="md" borderBottom={"1px solid lightgray"}>
            <Input
             disabled={isLoading || isdisable}
              value={askInputData}
              onChange={(e) => setaskInputData(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type your message..."
              border={"none"}
              flex="1"
              bg={"white"}
              borderRadius={0}
              pr={"50px"}
              _focus={{ boxShadow: "none" }}
              _hover={{ boxShadow: "none" }}
              _active={{ boxShadow: "none" }}
            />
            <InputRightElement
              width="4.5rem"
              color={askInputData ? "gray" : "#D2D2D2"}
            >
              {/* <Img src={SendIcon}  onClick={handleMessageSubmit} /> */}
              <RiSendPlane2Fill onClick={handleMessageSubmit} size={20} />
            </InputRightElement>
          </InputGroup>
          {widgetData["show-branded-text"] && (
            <Box
              pt={"2px"}
              pb={"2px"}
              mt={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              g
              fontSize={"12px"}
              color={"gray.500"}
              fontWeight={500}
              gap={1}
            >
              <Text>Powered by</Text>
              {/* <HiChatBubbleLeftRight /> */}
              <Text color={"black"} fontWeight={700}>
                HappyChat
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default ShareChatBot;
