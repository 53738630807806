import React, { useState } from "react";
import {
  Box,
  Button,
  ListItem,
  Text,
  Textarea,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/provider";
import FetchWithToken from "../../../utils/FetchWithToken";


const theme = extendTheme({
  colors: {
    custom: "#F33281", // Set the custom color
  },
});

export const DefaultPromptModal = ({ isOpen, onClose, botData }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const toast = useToast();

  const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;

  const [promptvalue, setPromptValue] = useState();

  const handlePromptChange = (e) => {
    const inputValue = e.target.value;
    // console.log(promptvalue , e.target.value);
    setPromptValue(inputValue);
  };

  const handleSaveClick = () => {
    const payload = {
      bot_id: botData.bot_id,
      prompt: promptvalue,
      user_id: user_id,
    };


    FetchWithToken(BASE_URL + `/chatbot/update_prompt`, 'PUT', payload)
      .then(data => {
        onClose()
        toast({
          title: data.message,
          status: "warning",
          duration: 4000,
          isClosable: true,
          // colorScheme: "custom"

        });
      });
  };

  const PromptModalBody = () => {
    return (
      <>
        <Text>
        Tailor your bot's answers to fit your unique needs by adding custom instructions to your prompts. With this powerful tool, you can make highly specific changes to achieve the desired output.
        </Text>
        <Text my={2} fontSize="3xl">
          Examples
        </Text>

        <UnorderedList mt={2} ml="25px">
          <ListItem>
            "Politely refuse to answer questions not related to the documentation provided."
          </ListItem>
          <ListItem>"Begin each answer with the phrase 'Here is some information that might help you' "</ListItem>
          <ListItem>
            "End each answer with the phrase 'Is there anything else I can help you with?' "
          </ListItem>
          <ListItem>
            "If the answer isn't found in the documentation provided, kindly suggest reaching out to the support team for assistance through the page: https://yourdomain.com/support/"
          </ListItem>
          {/* <ListItem>"Always answer in the form of a rhyming couplet."</ListItem> */}
        </UnorderedList>
        <Text my={2} fontSize="1xl">
          Custom Prompt
        </Text>
      </>
    );
  };

  //   const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}
      {/* <ChakraProvider theme={theme}> */}
      <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Customize Prompt (advanced) </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PromptModalBody />
            <Textarea
              colorScheme="teal"
              onChange={(e) => handlePromptChange(e)}
              placeholder="Enter any custom promt here..."
              value={promptvalue}
            />
          </ModalBody>

          <ModalFooter>

            <Button
              _hover={{ bg: "#EB6F0D" }}
              w={"130px"}
              h={"34px"}
              borderRadius={"65px"}
              onClick={() => handleSaveClick(onClose)}
              bg="#FF8321"
              color={"white"}
              mr={3}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* </ChakraProvider> */}
    </>
  );
};
