import { Box, Card, CardBody, Spinner, Text, useToast } from "@chakra-ui/react";
import {Users} from './Users'
import { useEffect, useState } from "react";
import FetchWithToken from "../utils/FetchWithToken";
// import { useToast } from "@chakra-ui/react"

const Analytics = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUserList] = useState([]);
  //   const handleCardCLick = () => {
  //     setShowUsers((prev) => !prev);
  //   };

  return (
    <>
      <Box>
        <Users />
      </Box>
    </>
  );
};

export default Analytics;
