import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  Flex,
  Tag,
  Box,
  useToast,
  Button,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DarkMode,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { HiLink } from "react-icons/hi2";
import QA from "../../../assets/chatbot/QA.png";
import urlIcon from "../../../assets/chatbot/URL.png";
import urlList from "../../../assets/chatbot/url_list.png";
import document from "../../../assets/chatbot/Document.png";
import rssFeedIcon from "../../../assets/chatbot/RssfeedIcon.png";
import FilepdfIcon from "../../../assets/chatbot/filePdf.png";
import sitemap from "../../../assets/chatbot/SiteMap.png";
import { MdOutlineSync } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import FetchWithToken from "../../../utils/FetchWithToken";
import { t } from "i18next";
import { BsTrash } from "react-icons/bs";

const UrlAccordian = ({ urlList }) => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              {`${urlList[0]}...`}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <UnorderedList>
            {urlList.map((url, index) => {
              return (
                <ListItem>
                  <Link color={"blue"} href={url} isExternal>
                    {url}
                  </Link>
                </ListItem>
              );
            })}
          </UnorderedList>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const UrlModal = ({ selectedSource, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Urls List</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={1} maxHeight={"400px"} overflow={"auto"}>
            <UnorderedList>
              {selectedSource?.source_url?.map((url, index) => {
                return (
                  <ListItem key={index}>
                    <Link color={"blue"} href={url} isExternal>
                      {url}
                    </Link>
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const UrlsTable = ({
  status,
  fetchSources,
  fetchSourceCategory,
  sources,
  botId,
  showToast,
  handleCallback,
}) => {
  const [sourceList, setSourceList] = useState();
  const [isDeleteSourceModalOpen, setIsDeleteSourceModalOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);

  const [isCardVisible, setCardVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteloading] = useState(false);

  const [isListModalVisible, setIsListModalVisible] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const toast = useToast();
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);

    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObject
    );

    return formattedDate;
  };
  const handleUrlListClick = () => {
    setIsListModalVisible(true);
  };

  useEffect(() => {
    // Define a comparison function for sorting
    // const total_characters = sources.reduce((total,source)=>{
    //   return total + source.charecter_count ;
    // })
    // console.log(total_characters);
    const compareStatus = (a, b) => {
      // Custom logic to sort by status
      if (a.status === "Scanned" && b.status !== "Scanned") {
        return -1; // 'Scanned' comes first
      } else if (a.status !== "Scanned" && b.status === "Scanned") {
        return 1; // 'Scanned' comes first
      } else {
        return 0; // No change in order
      }
    };

    // Sort the sourceList using the comparison function
    if (sources) {
      const sortedSourceList = sources.sort(compareStatus);
      setSourceList(sortedSourceList);
    }
  }, [sources]);

  // delete sub-urls
  const handleDeleteClick = (suburl_id) => {
    const data = {
      suburl_id: suburl_id,
    };
    FetchWithToken(
      BASE_URL + `/chatbot/delete_suburls?suburl_id=${suburl_id}`,
      "DELETE"
    )
      .then((data) => {
        if (data.message) {
          showToast("Source Deleted", data.message, "success");
          // toast({
          //   title: data.message,
          //   status: "success",
          //   duration: 4000,
          //   isClosable: true,
          // });
        } else if (data.error) {
          showToast("Deletion Failed", data.error, "error");
        }
      })
      .catch((err) => {
        showToast("Something went worng", err, "error");
        console.log(err);
      });
    fetchSources();
  };

  const handleSourceDelete = (source) => {
    setSelectedSource(source);
    setIsDeleteSourceModalOpen(true);
  };
  function formatCreatedAt(createdDate) {
    const formattedCreatedAt = new Date(createdDate).toLocaleString("en-US", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    return formattedCreatedAt;
  }
  //Handle Delete card
  const handleDeleteCardClick = () => {
    setCardVisible(true);
  };
  //Delete source
  const deleteSource = async (source) => {
    const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
    const PageCount = parseInt(source?.page_count, 10);

    const data = {
      source_id: source.source_id,
      bot_id: botId, //botData.bot_id,
      user_id: user_id,
      page_count: PageCount,
    };
    setIsDeleteloading(true);
    console.log("data", data);
    FetchWithToken(BASE_URL + "/chatbot/deletesource", "DELETE", data).then(
      (data) => {
        showToast("Source Deleted", data.message, "success");
        setIsDeleteloading(false);
        setIsDeleteSourceModalOpen(false);
        setCardVisible(false);
        fetchSources();
        fetchSourceCategory();
        // fetchBotData();
        handleCallback();
      }
    );
  };

  return (
    <div>
      {sourceList && sourceList.length > 0 ? (
        <TableContainer h={"400px"} w={"full"} mt={2} overflowY={"auto"}>
          <Table
            resize={"horizontal"}
            size="sm"
            variant="striped"
            colorScheme="gray"
          >
            <Thead>
              <Tr>
                <Th isNumeric>No.</Th>
                <Th resize={"horizontal"} fontWeight={700} fontSize={"12px"}>
                  Sources
                </Th>
                <Th fontWeight={700} fontSize={"12px"}>
                  Source Type
                </Th>
                <Th fontWeight={700} fontSize={"12px"}>
                  Status
                </Th>
                <Th fontWeight={700} fontSize={"12px"}>
                  {" "}
                  LAST UPDATED
                </Th>
                <Th fontWeight={700} fontSize={"12px"} isNumeric>
                  CHARACTERS
                </Th>
                <Th fontWeight={700} fontSize={"12px"}>
                  SYNC WEBSITE
                </Th>
                <Th fontWeight={700} fontSize={"12px"}>
                  REMOVE
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sources.map((source, index) => {
                return (
                  <Tr key={source.source_id}>
                    <Td isNumeric>{index + 1}</Td>
                    <Td
                      // colSpan={2}
                      // maxWidth={'200px'}
                      overflow={'hidden'}
                      onClick={() => setSelectedSource(source)}
                      fontWeight={500}
                      fontSize={"15px"}
                      _hidden={
                        {
                          overflow: 'visible'
                        }
                      }
                    >
                      {source.source_type === "url" && (
                        <Link
                          color={"blue"}
                          href={source.source_url}
                          isExternal
                        >
                          {source.source_url.length > 50
                            ? `${source.source_url.slice(0, 50)}...`
                            : source.source_url}
                        </Link>
                      )}
                      {source.source_type === "url_list" && (
                        //  <UrlAccordian urlList={source.source_url}/>
                        <Text
                          color={"blue"}
                          cursor={"pointer"}
                          onClick={handleUrlListClick}
                        >
                          {`${source.source_url[0]}...`}
                        </Text>
                      )}
                      {source.source_type === "sitemap" && (
                        <Link
                          color={"blue"}
                          href={source.source_url}
                          isExternal
                        >
                          {source.source_url.length > 50
                            ? `${source.source_url.slice(0, 50)}...`
                            : source.source_url}
                        </Link>
                      )}
                      {source.source_type === "rss_feed" && (
                        <Link
                          color={"blue"}
                          href={source.source_url}
                          isExternal
                        >
                          {source.source_url.length > 50
                            ? `${source.source_url.slice(0, 50)}...`
                            : source.source_url}
                        </Link>
                      )}
                      {source.source_type === "Q&A" && (
                        <Text>
                          QA
                        </Text>
                      )}
                      {source.source_type === "file" && (
                        <Text>
                          {source.source_url.length > 50
                            ? `${source.source_url.slice(0, 50)}...`
                            : source.source_url}
                        </Text>
                      )}
                      {source.source_type === "document" && (
                        <>
                          <Text>{source?.source_title}</Text>
                          {/* <Text> Document</Text> */}
                        </>
                      )}
                    </Td>
                    <Td fontWeight={500} fontSize={"15px"}>
                      <Flex gap={1} alignItems={"center"}>
                        {source.source_type === "url" && (
                          <Image src={urlIcon} alt="BotIcon" />
                        )}
                        {source.source_type === "url_list" && (
                          <Image src={urlList} alt="BotIcon" />
                        )}
                        {source.source_type === "sitemap" && (
                          <Image src={sitemap} alt="BotIcon" />
                        )}
                        {source.source_type === "rss_feed" && (
                          <Image boxSize='20px' src={rssFeedIcon} alt="BotIcon" />
                        )}
                        {source.source_type === "Q&A" && (
                          <Image src={QA} alt="BotIcon" />
                        )} 
                        {source.source_type === "file" && (
                          <Image src={FilepdfIcon} alt="BotIcon" />
                        )}
                        {source.source_type === "document" && (
                          <Image src={document} alt="BotIcon" />
                        )}
                        {source?.source_type?.toUpperCase().replace(/_/g, ' ')} 
                      </Flex>
                    </Td>
                    <Td fontWeight={500}>
                      <Tag
                        size={"md"}
                        fontSize={"15px"}
                        px={"15px"}
                        py={"5px"}
                        variant="outline"
                        colorScheme={
                          source.job_status === "Scanned" ||
                          source.job_status === "Trained"
                            ? "orange"
                            : "blue"
                        }
                        borderRadius={"full"}
                      >
                        {source.job_status}
                      </Tag>
                    </Td>
                    <Td fontWeight={500} fontSize={"15px"}>
                      {source?.created_ts ?formatDate(source.created_ts): ""}
                    </Td>
                    <Td fontWeight={500} fontSize={"15px"} isNumeric>
                      {source.charecter_count ? source.charecter_count : 0}
                    </Td>
                    <Td>{/* <MdOutlineSync /> */}</Td>
                    <Td>
                      <IconButton
                        isRound={true}
                        variant="ghost"
                        isDisabled = {status === "Scanning" || status === "Training"}
                        colorScheme="red"
                        aria-label="Delete"
                        fontSize="20px"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSourceDelete(source);
                        }}
                        icon={<RiDeleteBin6Line />}
                      />

                      {/* <RiDeleteBin6Line
                        onClick={() => handleDeleteClick(source.suburl_id)}
                        size={22}
                        color="red"
                    
                      /> */}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Box width={"100%"} height={"100%"}>
          <Text textAlign={"center"} fontSize={"18px"}>
            Wait, No sources....
          </Text>
        </Box>
      )}
      {/* Url List modal */}

      {isListModalVisible && (
        <UrlModal
          selectedSource={selectedSource}
          isOpen={isListModalVisible}
          onClose={() => setIsListModalVisible(false)}
        />
      )}

      {/* Source Deletion Modal */}
      {
        isDeleteSourceModalOpen && 
        <Modal
        size={"3xl"}
        isOpen={isDeleteSourceModalOpen}
        onClose={() => {
          setIsDeleteSourceModalOpen(false);
          setCardVisible(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display={"flex"} alignItems={"baseline"} gap={1}>
            <Text>
              {selectedSource?.source_title
                ? selectedSource?.source_title
                : selectedSource?.source_url}
            </Text>
            <Text fontSize={"13px"} color={"#b1b1b1"}>
              {selectedSource?.source_type === "file"
                ? "DOCUMENT"
                : selectedSource?.source_type?.toUpperCase()}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              direction={["column", "row"]}
              justifyContent="space-between"
              alignItems={["flex-start", "center"]}
              spacing={2}
            >
              <Text
                p="3px 10px"
                fontSize="xs"
                background="#BFFFBF"
                color="#007500"
                fontWeight="500"
                borderRadius="100vh"
              >
                {t("Chatbots.Details.Indexed")}
              </Text>
              <Text fontSize="sm" fontWeight="400">
                {selectedSource?.page_count} Pages
              </Text>
              <Text fontSize="sm" fontWeight="400">
                {t("Chatbots.Details.Created At")}:{" "}
                {formatCreatedAt(selectedSource?.created_ts)}
              </Text>
            </Stack>

            <Box>
              {!isCardVisible && (
                <Box
                  pb={5}
                  display="flex"
                  alignItems="center"
                  color="red"
                  mt={3}
                  onClick={handleDeleteCardClick}
                  cursor="pointer"
                >
                  <BsTrash />
                  <Text ml={1} fontSize="sm">
                    {/* Delete */}
                    {t("Chatbots.Details.Delete")}
                  </Text>
                </Box>
              )}
              {isCardVisible && (
                <Box p={2}>
                  <Card mt={2}>
                    <CardHeader
                      fontSize={"18px"}
                      fontWeight={600}
                      p={"10px 20px"}
                    >
                      {t("Chatbots.Details.Delete Source Document?")}
                    </CardHeader>
                    <CardBody color={"gray.600"} p={"10px 20px"}>
                      {t(
                        "Chatbots.Details.By choosing to delete this source, you'll be removing all of its pages from your bot's index. Please be aware that it might take alittle while for the changes to take effect across our services, and for the sources to be fully removed from chat results. Are you certain you want to proceed with deleting this source?"
                      )}
                    </CardBody>
                    <CardFooter>
                      <Button
                        onClick={() => {
                          setIsDeleteSourceModalOpen(false);
                          setCardVisible(false);
                        }}
                        mr={1}
                      >
                        {t("Chatbots.Details.Cancel")}
                      </Button>
                      <DarkMode>
                        <Button
                          isLoading={isDeleteLoading}
                          onClick={() => deleteSource(selectedSource)}
                          colorScheme="red"
                          ml={1}
                        >
                          {t("Chatbots.Details.Delete")}
                        </Button>
                      </DarkMode>
                    </CardFooter>
                  </Card>
                </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      }
      
    </div>
  );
};
