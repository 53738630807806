

import { useEffect, useState } from 'react';
import {
    Box,
    Input,
    IconButton,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Text,
    Table, Thead, Tbody, Tr, Th, Td, Avatar, Button
} from '@chakra-ui/react';
import { BsChatSquare, BsFillChatFill, BsFillSendFill, BsOctagon, BsStarFill, BsX } from 'react-icons/bs';
import { BeatLoader } from "react-spinners";
import { AiOutlineBars, AiOutlineUser } from 'react-icons/ai';
import { MdFileDownload, MdRefresh } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import FetchWithToken from '../../utils/FetchWithToken';



const LogDetails = () => {

    const BASE_URL = process.env.REACT_APP_BASE_URL


    const [chatHistory, setChatHistory] = useState([])
    const [rotationAngle, setRotationAngle] = useState(0);
    const location = useLocation();
    const botData = location.state?.botData;
    const [isLoading, setIsloading] = useState(false);


    const handleRotateClick = () => {
        setRotationAngle(rotationAngle + 360);
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        const formattedTime = date.toLocaleString(undefined, options);
        return formattedTime;
    }

    function fetchSource() {
        const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
        FetchWithToken(BASE_URL + "/chatbot/log?user_id=" + user_id + "&bot_id=" + botData.bot_id, 'GET')
            .then(data => {
                setChatHistory(data.Chatbot_history[0].history)
            });
    }
    const handleExport = () => {
        // Perform export functionality
        setIsloading(true);

        FetchWithToken(BASE_URL + "/chatbot/log/export?&bot_id=" + botData.bot_id, 'GET')
            .then(data => {
                setIsloading(false);
                // Initiate download
                window.open(data.Success, '_blank');
            });
    };

    useEffect(() => {
        fetchSource()
    }, [])

    return (
        <Box>
            <Box textAlign={'end'}>
                <Button fontWeight="bold" isLoading={isLoading} onClick={handleExport} leftIcon={<MdFileDownload />} colorScheme='teal' variant='solid'>
                    Export
                </Button>
            </Box>
            <Table variant="simple" colorScheme="gray" style={{ maxWidth: '100%' }}>
                <Thead>
                    <Tr>
                        <Th>Question</Th>
                        <Th>Answer</Th>
                        <Th>At</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {chatHistory.map((message, index) => (
                        <Tr key={index}>
                            <Td fontWeight={500} color={'gray.700'} style={{ fontSize: '14px', padding: '8px' }}>
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                    <Avatar w={'1.5em'} h={'1.5em'} bg='red.500' icon={<AiOutlineUser />} />
                                    {message.user.message}
                                </Box>
                            </Td>
                            <Td fontWeight={500} color={'gray.700'} style={{ fontSize: '15px', padding: '8px' }}>
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                    <Avatar w={'1.5em'} h={'1.5em'} bg='teal' icon={<BsFillChatFill />} />
                                    {message.bot.message}
                                </Box>
                            </Td>
                            <Td fontWeight={500} color={'gray.700'} style={{ fontSize: '13px', padding: '8px' }}>
                                {formatTimestamp(message.created_ts)}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

        </Box>


    );
}


export default LogDetails;