import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link, useLocation, NavLink, useNavigate } from "react-router-dom";

import { GoogleLogin } from '@react-oauth/google';

import logoDark from '../assets/logoDark.png';
import { AppContext } from "../utils/AppContext";
import { useTranslation } from 'react-i18next';
import queryString from "query-string";
import analyticsService from "../utils/analyticsService";




const Login = () => {

  const { t, i18n } = useTranslation();
  // const lang = navigator.language;
  // const lang = localStorage.getItem("language");

  const BASE_URL = process.env.REACT_APP_BASE_URL

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isLoading, setisLoading] = useState(false)
  const [team_id, setTeamId] = useState(null)
  const location = useLocation();
  // const { globalTeam, setGlobalTeam } = useContext(AppContext);
  const [urlParams, seturlParams] = useState(queryString.parse(window.location.search))
  const navigate = useNavigate();
  const toast = useToast()



  // useEffect(() => {
  //   const lang = navigator.language;
  //   console.log(lang);
  //   i18n.changeLanguage(lang);
  //   const searchParams = new URLSearchParams(location.search);
  //   const redirect = searchParams.get("redirect");

  //   let teamId = null;

  //   if (redirect) {
  //     const teamIdParam = new URLSearchParams(redirect.split("?")[1]);
  //     setTeamId(teamIdParam.get("team_id"))
  //   }
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setIsPasswordValid(false);
      return; // Prevent form submission if password is not valid
    }
    setisLoading(true)
    const logindata = { email: email, password: password, provider: 'email' }
    if (team_id) {
      logindata["team_id"] = team_id;
    }
    await fetch(BASE_URL + '/login', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(logindata)
    })
      .then(response => response.json())
      .then(data => {
        setisLoading(false)
        if (data.user_id) {
          localStorage.setItem("docsbot-users", JSON.stringify(data));
          var existingDataString = localStorage.getItem("docsbot-users");
          var existingData = JSON.parse(existingDataString);
          existingData.selectedTeam = data.team_details[0];
          var updatedDataString = JSON.stringify(existingData);
          localStorage.setItem("docsbot-users", updatedDataString);
          if (urlParams.lang) {
            // navigate(`/admin-dashboard/dashboard?lang=${urlParams.lang}`);
            navigate(`/admin-dashboard/chatbots?lang=${urlParams.lang}`);
          } else {
            // navigate(`/admin-dashboard/dashboard`);
            navigate(`/admin-dashboard/chatbots`);
          }

          const team = data.team_details.filter((item)=> item.user_role === "owner" )
          // console.log(team, data.team_details);
          const now = new Date();
          // Format the date and time as a string in 'YYYY-MM-DD HH:mm:ss' format
          const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
        
          analyticsService.trackEventInProduction('login', {
            email: email,
            login_method: 'form',
            last_login_date: '',
            first_login_date: '',
            login_date: formattedDate,
            login_count: '1',
            user_id: data.user_id,
            user_name: '',
            team_name: team[0].team_name,
            user_role: team[0].user_role,
          });
        } else if (data.error) {
          toast({
            title: "Resend verification email if you haven't recived it.",
            status: 'warning',
            duration: 5000,
            isClosable: true,
          })
          navigate('/verify-email', { state: { email: email } })
        } else {
          toast({
            title: data.message,
            status: 'warning',
            duration: 4000,
            isClosable: true,
          })
        }

      })
      .catch(err => {
        setisLoading(false)
        toast({
          title: 'An error occurred. Please try again later.',
          status: 'warning',
          duration: 4000,
          isClosable: true,
        })
        console.log(err)
      });
  };


  const handleAuthLogin = async (credential, provider) => {

    //console.log(credential)
    const loginData = {
      token: credential,
      provider: provider
    }
    if (team_id) {
      loginData["team_id"] = team_id;
    }
    setisLoading(true)
    // console.log(loginData)
    await fetch(BASE_URL + '/login', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(loginData)
    })
      .then(response => response.json())
      .then(data => {
        setisLoading(false)
        if (data.user_id) {
          localStorage.setItem("docsbot-users", JSON.stringify(data));
          var existingDataString = localStorage.getItem("docsbot-users");
          var existingData = JSON.parse(existingDataString);
          existingData.selectedTeam = data.team_details[0];
          var updatedDataString = JSON.stringify(existingData);
          localStorage.setItem("docsbot-users", updatedDataString);
          if (urlParams.lang) {
            // navigate(`/admin-dashboard/dashboard?lang=${urlParams.lang}`);
            navigate(`/admin-dashboard/chatbots?lang=${urlParams.lang}`);
          } else {
            // navigate(`/admin-dashboard/dashboard`);
            navigate(`/admin-dashboard/chatbots`);
          }
          const team = data.team_details.filter((item)=> item.user_role === "owner" )
          const now = new Date();
          // Format the date and time as a string in 'YYYY-MM-DD HH:mm:ss' format
          const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;

          analyticsService.trackEventInProduction('login', {
            email: email,
            login_method: 'google',
            last_login_date: '',
            first_login_date: '',
            login_date: formattedDate,
            login_count: '1',
            user_id: data.user_id,
            user_name: '',
            team_name: team[0].team_name,
            user_role: team[0].user_role,
          });
        } else if (data.error) {
          toast({
            title: "Resend verification email if you haven't recived it.",
            status: 'warning',
            duration: 5000,
            isClosable: true,
          })
          navigate('/verify-email', { state: { email: email } })
        } else {
          toast({
            title: data.message,
            status: 'warning',
            duration: 4000,
            isClosable: true,
          })
        }

      })
      .catch(err => {
        setisLoading(false)
        toast({
          title: 'An error occurred. Please try again later.',
          status: 'warning',
          duration: 4000,
          isClosable: true,
        })
        console.log(err)
      });
  }



  const linkStyles = {
    display: 'inline-block',
    padding: '1px 5px',
    color: '#FF8321',
    textDecoration: 'none',
    fontWeight: '500',
    transition: 'background-color 0.3s ease',
  };


  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword.length < 8) {
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }
  };

  function handleMouseEnter(event) {
    event.target.style.textDecoration = 'underline';
  }

  function handleMouseLeave(event) {
    event.target.style.textDecoration = 'none';
  }

  function signupLanguage() {
    if (urlParams.lang) {
      return `/signup?lang=${urlParams.lang}`
    } else {
      return '/signup'
    }
  }

  useEffect(() => {
    seturlParams(queryString.parse(window.location.search))
    i18n.changeLanguage(urlParams.lang ? urlParams.lang : 'en');
    // localStorage.setItem("language", lang);
  }, [])
  return (
    <Flex minHeight="100vh" width="full" align="center" >
      <Box width="full" align="center" >
        <Image
          src={logoDark}
          alt='logodark'
          h={'40px'}
        />
        <Box my={4} p={8} maxWidth="450px" borderWidth={1} borderRadius={10} boxShadow="lg">
          {/* <Image src="https://docsbot.ai/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdocsbot-logo.c6071a27.png&w=256&q=75" alt="Logo" mb={4} /> */}
          {/* <Image
             src={logoDark}
             alt='logodark'
             h={'40px'}
         /> */}
          <Box>
            <Heading as="h3" size="lg">
              {t("Sign_In_to_Account")}
            </Heading>
            <Box mt={3}>
              <GoogleLogin
                onSuccess={credentialResponse => {
                  handleAuthLogin(credentialResponse.credential, "google");
                }}
                onError={() => {
                  toast({
                    title: 'Login Failed',
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                  })
                }}
              />
            </Box>
          </Box>

          <Flex align="center" justify="center" my={6}>
            <Divider w="40%" borderColor="#bdbdbd" />
            <Text mx={4} fontWeight="bold" color="#bdbdbd">or</Text>
            <Divider w="40%" borderColor="#bdbdbd" />
          </Flex>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>{t("email_address")}</FormLabel>
              <Input type="email" mb={3} onChange={(e) => setEmail(e.target.value)} required />

              <FormLabel>{t("password")}</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                required
                isInvalid={!isPasswordValid}
              />
              {!isPasswordValid && (
                <Text textAlign={'start'} color="red" fontSize="sm" mt={0}>
                  {t("Password must be at least 8 characters long")}.
                </Text>
              )}
              {/* <Input type="password" mb={3} value={password} onChange={(e) => setPassword(e.target.value)} /> */}
              <Flex justifyContent={'end'} alignItems={'center'} mt={2}>
                <Link style={linkStyles} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} to="/forget-password">{t("forgot_password")}?</Link>
              </Flex>
              <Button isLoading={isLoading} mt={4} w={'100%'} _hover={{ bg: '#EB6F0D' }} color={'white'} bg={'#FF8321'} type="submit">
                {t("login")}
              </Button>
              <Flex justifyContent={'center'} alignItems={'center'} mt={2}>
                <Text>{t("don't_have_an_account")}?</Text>
                <Link style={linkStyles} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} to={signupLanguage()}>{t("sign_up")}</Link>
              </Flex>
            </FormControl>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default Login;
