import { Box, Flex, Progress, Tag, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export const ScanningProgressBar = ({ urlList, message }) => {
  const [scannedPercentage, setScannedPercentage] = useState(0);

  const calculatePercentage = (urlList) => {
    const total = urlList.length;
    // let scanned = 0;
    let scanning = 0;
    urlList.forEach((source) => {
      // if (source.job_status === "Scanned" || source.job_status === "Trained") {
      //   scanned = scanned + 1;
      // }
      if (source.job_status === "Scanning") {
        scanning = scanning + 1;
      }
    });
    
    const percentScanning = Math.round((scanning / total) * 100);
    // const percentage = Math.round((scanned / total) * 100);
    // setScannedPercentage(percentage);
    setScannedPercentage(100-percentScanning);
  };
  useEffect(() => {
    if (urlList.length > 0) calculatePercentage(urlList);
  }, [urlList]);

  return (
    <Box
      overflow={"hidden"}
      pb={0}
      mt={1}
      borderRadius={"10px"}
      backgroundColor={"#fff"}
    >
      <Flex justifyContent={"space-between"} p={3}>
        <Flex>
          <Text fontWeight={500} fontSize={"14px"}>
            {" "}
            Scan Progress -{" "}
          </Text>
          <Text color={"#FF8321"}>{` ${scannedPercentage}%`}</Text>
        </Flex>
        {}
        <Flex gap={1}>
          {scannedPercentage === 100 ? (
            <>
              <Text fontWeight={700} fontStyle={"italic"} fontSize={"14px"}>
                Scan completed!{" "}
              </Text>
              <Text fontWeight={400} fontStyle={"italic"} fontSize={"14px"}>
                Now it's time to train your chatbot.
              </Text>
            </>
          ) : (
            <Text fontWeight={400} fontStyle={"italic"} fontSize={"14px"}>
              We are scanning the website and collecting all of the appropriate
              information.
            </Text>
          )}

          {scannedPercentage === 100 ? (
            <Tag
              borderRadius={"full"}
              bg={"green.400"}
              color={"white"}
              variant={"subtle"}
            >
              Ready
            </Tag>
          ) : (
            <Tag colorScheme="green" variant={"subtle"}>
              In Progress
            </Tag>
          )}
        </Flex>
      </Flex>
      <Progress colorScheme="orange" size={"xs"} value={scannedPercentage} />
    </Box>
  );
};
