import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    Textarea,
    RadioGroup,
    Stack,
    Radio,
    Text,
    Select,

} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import FetchWithToken from "../../utils/FetchWithToken";
import { useTranslation } from "react-i18next";
import analyticsService from "../../utils/analyticsService";

const CreateNewBot = ({ isOpen, onClose, onCancel, botData, hasAccess }) => {
    const { t, i18n } = useTranslation();

    const BASE_URL = process.env.REACT_APP_BASE_URL

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        bot_name: "",
        description: "",
        privacy: "public",
        openai_model: "gpt3",
        bot_language: "Auto Detect"
    });
    const options = [
        {
            value: "gpt3",
            label: "GPT 3.5 (Original ChatGPT)",
            description: "The fastest and the cheapest ($0.005/question) model good for most use cases.",
        },
        {
            value: "gpt4",
            label: "GPT-4",
            description: "Most powerful but slower and more expensive ($0.09/question) model good for advanced reasoning or content creation needs.",
        },
    ];
    const languageOptions = [
        t("Chatbots.NewChatbot.Auto Detect"),
        'Arabic',
        'Belarusian',
        'Bulgarian',
        'Chinese',
        'Croatian',
        'Czech',
        'Danish',
        'Dutch',
        'English',
        'Estonian',
        'Filipino',
        'Finnish',
        'French',
        'German',
        'Greek',
        'Hebrew',
        'Hindi',
        'Hungarian',
        'Icelandic',
        'Indonesian',
        'Irish',
        'Italian',
        'Japanese',
        'Javanese',
        'Kannada',
        'Korean',
        'Kurdish',
        'Latin',
        'Latvian',
        'Lithuanian',
        'Malay',
        'Mongolian',
        'Nepali',
        'Norwegian',
        'Persian',
        'Polish',
        'Portuguese',
        'Romanian',
        'Russian',
        'Serbian',
        'Slovak',
        'Slovenian',
        'Spanish',
        'Swedish',
        'Tamil',
        'Telugu',
        'Thai',
        'Turkish',
        'Ukrainian',
        'Urdu',
        'Vietnamese',
        'Welsh',
        'Yiddish',
    ];

    const [isSubmitting, setisSubmitting] = useState(false)
    const [firstQuestion, setFirstQuestion] = useState([])
    // const [newQuestion, setNewQuestion] = useState('');
    const [deletedQuestions, setDeletedQuestions] = useState([]);
    const handleNameChange = (event) => {
        setFormData({ ...formData, bot_name: event.target.value });
    };

    const handleDescriptionChange = (event) => {
        setFormData({ ...formData, description: event.target.value });
    };

    const handlePrivacyChange = (event) => {
        setFormData({ ...formData, privacy: event });
    };

    const handleOpenAIModalChange = (event) => {
        setFormData({ ...formData, openai_model: event.target.value });
    };

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setFormData({ ...formData, bot_language: selectedLanguage });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
        const team_id = JSON.parse(localStorage.getItem("docsbot-users")).selectedTeam.team_id;
        setisSubmitting(true)

        const data = formData;
        data["user_id"] = user_id
        data["team_id"] = team_id
        // console.log(data)
        if (botData) {
            data["bot_id"] = botData.bot_id;
            data["questions"] = firstQuestion;
            data["delete_ques"] = deletedQuestions;

            FetchWithToken(BASE_URL + '/chatbot/updatebot', 'POST', data)
                .then(data => {
                    if (data) {
                        setisSubmitting(false);
                        onClose();

                        // console.log(data)
                    }
                    navigate('/admin-dashboard/chatbots')
                });
        } else {

            FetchWithToken(BASE_URL + '/chatbot', 'POST', data)
                .then(resData => {
                    if (resData) {
                        setisSubmitting(false);
                        onClose();

                        setFormData({
                            bot_name: "",
                            description: "",
                            privacy: "public",
                            openai_model: "gpt3",
                            bot_language: "Auto Detect"
                        });
                        navigate('/admin-dashboard/chatbots')
                        //console.log(data)
                        
                        analyticsService.trackEventInProduction('chatbot_created', {
                            chatbot_count: resData.bot_count,  //'1',
                            bot_name: data.bot_name,
                            bot_id: resData.bot_id,
                            bot_description: data.description,
                            bot_privacy: data.privacy,
                            bot_ai_modal: data.openai_model,
                            bot_language: data.bot_language,
                          });
                    }
                    navigate('/admin-dashboard/chatbots')
                });
        }
    };


    // const setFirstQuestionFormat = (questions) => {
    //     const transformedQuestions = questions.map((question) => {
    //         return {
    //             bot_id: question.bot_id,
    //             ques_id: question.ques_id,
    //             text: question.question,
    //         };
    //     });
    //     setFirstQuestion(transformedQuestions);
    // };

    useEffect(() => {

        if (isOpen) {
            if (botData) {

                setFormData({
                    bot_name: botData.bot_name,
                    description: botData.description,
                    privacy: botData.privacy,
                    openai_model: "gpt3",
                    bot_language: botData.bot_language

                });

                // FetchWithToken(BASE_URL + "/chatbot/questions?bot_id=" + botData.bot_id, 'GET')
                //     .then(data => {
                //         setFirstQuestionFormat(data.quesions)
                //     });
            }
        }

    }, [botData, isOpen])




    return (
        <Modal isOpen={isOpen} onClose={onCancel}>
            <ModalOverlay />{t("Dashboard.Train a new knowledge base with your custom documentation and content")}
            <ModalContent marginTop={5}>
                <ModalHeader color={'white'} p={'25px'} fontSize={'17px'} bg={'var(--Web-color-orange)'}>
                    <Text>{botData ? 'Edit Your Bot' : t("Chatbots.NewChatbot.Create A New Bot")}</Text>
                    <Text pt={2} pb={2} fontSize={'14px'}>{t("Chatbots.NewChatbot.Create your new bot by providing the requested information. Once created, you can add your source documentation to start interacting with your chatbot instantly")}</Text>
                </ModalHeader>
                <ModalCloseButton color={'white'} />
                <form onSubmit={handleSubmit}>
                    <ModalBody pb={6} borderBottomWidth="1px"
                        borderBottomColor="gray.200">

                        <FormControl>
                            <FormLabel>{t("Chatbots.NewChatbot.Name")}</FormLabel>
                            <Input fontSize={14} required name="name" value={formData.bot_name} placeholder={t("Chatbots.NewChatbot.What would you like to call your bot?")} onChange={handleNameChange} />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>{t("Chatbots.NewChatbot.Description")}</FormLabel>
                            <Textarea fontSize={14} required name="description" value={formData.description} placeholder={t("Chatbots.NewChatbot.Provide a description and usage of your bot, such as 'A website support chatbot that assists visitors")} onChange={handleDescriptionChange} />
                        </FormControl>

                        <FormControl paddingTop={5}>
                            <FormLabel>{t("Chatbots.NewChatbot.Privacy")}</FormLabel>
                            <RadioGroup name="privacy"
                                defaultValue="public"
                                value={formData.privacy}
                                onChange={handlePrivacyChange}>
                                <Stack>
                                    <Radio colorScheme='orange' alignItems='baseline' name="privacy" value='public'>
                                        <Text fontWeight={600}>{t("Chatbots.NewChatbot.Public Access")}</Text>
                                        <Text fontSize={13}>{t("Chatbots.NewChatbot.Allows for embedding on the frontend of websites.")}</Text>
                                    </Radio>
                                    <Radio colorScheme='orange' isDisabled={!hasAccess?.PRIVATE_BOT.enabled} alignItems='baseline' name="privacy" value='private'>
                                        <Text fontWeight={600}>{t("Chatbots.NewChatbot.Private")}</Text>
                                        <Text fontSize={13}>{t("Chatbots.NewChatbot.Restricted API access for authenticated users only. Ideal for internal company content.")}</Text>
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                        <FormControl paddingTop={5}>
                            <FormLabel>{t("Chatbots.NewChatbot.AI Modal")}</FormLabel>
                            <Select value={formData.openai_model} onChange={handleOpenAIModalChange}>
                                {options.map((option) => {
                                    if (option.value === "gpt4") {
                                        return (
                                            <option fontWeight={600} disabled={!hasAccess?.GPT4.enabled} key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        );
                                    }
                                    return (
                                        <option fontWeight={600} key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    );
                                })}
                            </Select>
                            {/* <Text pt={1} fontSize={12}>{options.find((option) => option.value === formData.openai_model)?.description}</Text> */}
                        </FormControl>

                        <FormControl paddingTop={5}>
                            <FormLabel>{t("Chatbots.NewChatbot.Language")}</FormLabel>
                            <Select
                                fontSize={14}
                                value={formData.bot_language}
                                onChange={handleLanguageChange}
                                required
                            >
                                {languageOptions.map((language, index) => (
                                    <option key={index} value={language}>
                                        {language}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter display={'flex'} justifyContent={'space-evenly'}>
                        <Button w={'115px'} borderColor={'blackAlpha.500'} borderRadius={50} onClick={onCancel} variant={'outline'}>{t("Chatbots.NewChatbot.Cancel")}</Button>
                        <Button _hover={{ bg: 'var(--Web-color-orange)' }} w={'115px'} borderRadius={50} isLoading={isSubmitting} type='submit' bg={'var(--Web-color-orange)'} color={'var(--Web-color-white)'} mr={3}>
                            {botData ? 'Edit Bot' : t("Chatbots.NewChatbot.Create Bot")}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );

};

export default CreateNewBot;