// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/Fonts/WEB/fonts/Satoshi-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../font/Fonts/WEB/fonts/Satoshi-Regular.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Satoshi';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n  font-weight: normal;\n  font-style: normal;\n}\n\n\n\nbody {\n  margin: 0;\n  font-family: 'Satoshi', sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  \n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.fade-in-out {\n  animation: fadeInOut 3s;\n}\n\n@keyframes fadeInOut {\n  0% {\n    opacity: 0;\n  }\n\n  25% {\n    opacity: 1;\n  }\n\n  75% {\n    opacity: 1;\n  }\n\n  100% {\n    opacity: 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB;6DACuE;EACvE,mBAAmB;EACnB,kBAAkB;AACpB;;;;AAIA;EACE,SAAS;EACT,6CAA6C;EAC7C,mCAAmC;EACnC,kCAAkC;;AAEpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":["@font-face {\n  font-family: 'Satoshi';\n  src: url('../font/Fonts/WEB/fonts/Satoshi-Regular.woff2') format('woff2'),\n       url('../font/Fonts/WEB/fonts/Satoshi-Regular.woff') format('woff');\n  font-weight: normal;\n  font-style: normal;\n}\n\n\n\nbody {\n  margin: 0;\n  font-family: 'Satoshi', sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  \n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.fade-in-out {\n  animation: fadeInOut 3s;\n}\n\n@keyframes fadeInOut {\n  0% {\n    opacity: 0;\n  }\n\n  25% {\n    opacity: 1;\n  }\n\n  75% {\n    opacity: 1;\n  }\n\n  100% {\n    opacity: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
