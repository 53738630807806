import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from './AppContext';
import FetchWithToken from './FetchWithToken';

const withAccessControl = (WrappedComponent) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const WithAccessControl = (props) => {
    const [accountsDetails, setAccountsDetails] = useState(null);
    const [packageDetails, setPackageDetails] = useState(null);
    const [hasAccess, setHasAccess] = useState(null);

    // const { globalTeam, setGlobalTeam } = useContext(AppContext);

    // Fetch accounts stats
    const fetchAccountsStats = async () => {
      try {
        // const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;
        const selectedTeam = JSON.parse(localStorage.getItem('docsbot-users'))?.selectedTeam;
        FetchWithToken(`${BASE_URL}/account/stats?team_id=${selectedTeam.team_id}`, 'GET')
          .then(data => {
            setAccountsDetails(data);
          });

      } catch (error) {
        console.log(error);
      }
    };

    // Fetch package details
    const fetchPackageDetails = async () => {
      try {
        FetchWithToken(`${BASE_URL}/packages?plan=${accountsDetails.current_plan}`, 'GET')
          .then(data => {
            setPackageDetails(data.features);
          });
      } catch (error) {
        console.log(error);
      }
    };

    const handleCallback = () => {
      fetchAccountsStats();
      fetchPackageDetails();
    };
    useEffect(() => {
      fetchAccountsStats();
    }, []);
    useEffect(() => {
      if (!packageDetails) {
      accountsDetails &&  fetchPackageDetails();
      }
    }, [accountsDetails,packageDetails]);


    useEffect(() => {
      if (packageDetails && accountsDetails) {
        const result = checkPackageDetails(packageDetails, accountsDetails);
        setHasAccess(result);
      }
    }, [packageDetails, accountsDetails]);

    const checkPackageDetails = (packageDetails, userStats) => {
      const result = {};
      for (const [key, value] of Object.entries(packageDetails)) {
        if (key === 'BOTS') {
          result[key] = { enabled: userStats && Number(userStats.bots_count) < Number(value.count) ? (value.ENABLED ? true : false) : false };
        } else if (key === 'QUESTIONS') {
          result[key] = { enabled: userStats && Number(userStats.questions) < Number(value.count) ? (value.ENABLED ? true : false) : false };
        } else if (key === 'SOURCE_PAGES') {
          result[key] = { enabled: userStats && Number(userStats.sources_count) < Number(value.count) ? (value.ENABLED ? true : false) : false };
        } else if (key === 'USERS') {
          result[key] = { enabled: userStats && Number(userStats.team_members) < Number(value.count) ? (value.ENABLED ? true : false) : false };
        } else if (key === 'PRIVATE_BOT') {
          result[key] = { enabled: userStats && Number(userStats.private_bots_count) < Number(value.count) ? (value.ENABLED ? true : false) : false };
        } else if (key === 'SOURCES') {
          result[key] = value;
        } else {
          result[key] = { enabled: value.ENABLED };
        }
      }
      return result;
    };

    // Pass the fetched data as props to the wrapped component
    const modifiedProps = {
      ...props,
      details: accountsDetails,
      Package: packageDetails,
      hasAccess: hasAccess,
      handleCallback: handleCallback,
    };

    return <WrappedComponent {...modifiedProps} />;
  };

  return WithAccessControl;
};

export default withAccessControl;
