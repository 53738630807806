import React, { useEffect, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  IconButton,
  Box,
  Tooltip,
  Button,
  useToast,
  InputGroup,
  InputLeftElement,
  Input,
  Spinner,
  Flex,
  Select,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DeleteIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import FetchWithToken from "../utils/FetchWithToken";

import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { IoCalendarOutline } from "react-icons/io5";
import { BiExport } from "react-icons/bi";

const ShowConfirmationModal = ({ isOpen, onClose, userEmail, callback }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);

  const comfirmDeletion = async () => {
    const body = {
      email: userEmail,
    };
    setDeleting(true);
    FetchWithToken(BASE_URL + "/deleteuser", "DELETE", body)
      .then((data) => {
        setDeleting(false);
        onClose();
        toast({
          title: data.message,
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      })
      .then(
        // console.log('in callback')
        callback()
      )
  };
  // const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        isCentered
      >
        <ModalOverlay />
        <ModalContent pt={4}>
          {/* <ModalHeader><WarningTwoIcon w={7} h={7} color="red.500" /> Delete team account</ModalHeader> */}
          {/* <ModalCloseButton /> */}
          <ModalBody display={"flex"} ms={"10px"}>
            <WarningTwoIcon w={7} h={7} color="red.500" />
            <Box mx={3}>
              <Text fontSize="lg" as="b">
                Delete User's account
              </Text>
              <Text fontSize="sm">
                Are you sure want to delete this user{" "}
                <Text as="b">{userEmail}</Text> account? All of your data will
                be permanently removed from our servers forever. This action
                cannot be undone.
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              mx={3}
              minWidth="100px"
              onClick={comfirmDeletion}
            >
              {deleting ? <Spinner /> : "Confirm"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
// export default ShowConfirmationModal;

export const Users = () => {
  const toast = useToast();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [totalPages, setTotalPages] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [loader, setLoader] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUserList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectPlan, setSelectPlan] = useState('');
  const [sourceCount, setSourceCount] = useState();
  const [botCount, setBotCount] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const user_id = JSON.parse(localStorage.getItem("docsbot-users"))?.user_id;
  // Date object
  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, '0');
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();

  // we will display the date as YYYY-MM-DD
  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // const controllerRef = useRef<AbortController | null>();

  const fetchUsers = async () => {
    // Create an instance of AbortController
    // const abortController = new AbortController();

    const body = {
      // team_id: selectedTeam.team_id,
      // user_role: selectedTeam.user_role,
      email: searchInput,
      start_date: startDate,
      end_date: endDate,
      package_type: selectPlan,
      min_source_pages: sourceCount,
      min_bot_count: botCount,
      order: ""
    };
    // Pass the abort signal from the controller to the fetch request options
    //   const requestOptions = {
    //   method: "POST",
    //   signal: abortController.signal,
    //   body: JSON.stringify(body),
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // };
    setIsLoading(true);
    FetchWithToken(BASE_URL + "/admin?page=" + pageNo, "POST", body).then(
      (data) => {
        setIsLoading(false);
        if (data.response) {
          console.log(data);
          setUserList(data.response);
          setTotalPages(data.total_page);
        } else {
          setUserList([]);
          setTotalPages(0);
        }
      }
    );
  };
  useEffect(() => {
    fetchUsers();
  }, [pageNo, searchInput, endDate, startDate, selectPlan, sourceCount, botCount]);

  const handleDeleteUsers = (userEmail) => {
    const body = {
      // team_id: selectedTeam.team_id,
      // user_role: selectedTeam.user_role,
      email: userEmail,
    };
    // setIsLoading(true);
    FetchWithToken(BASE_URL + "/deleteuser", "DELETE", body)
      .then((data) => {
        // setIsLoading(false);
        toast({
          title: data.message,
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      })
      .then(fetchUsers());
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmModal(false);
  };

  const handleDeleteUser = async (userEmail) => {
    setShowConfirmModal(true);
    setUserEmail(userEmail);
  };

  const pageChangeButton = (direction) => {
    if (direction === "right") {
      setPageNo((prev) => parseInt(prev) + 1);
    } else if (direction === "left") {
      setPageNo((prev) => parseInt(prev) - 1);
    }
  };
  const pageNoChange = (e) => {
    if (parseInt(e.target.value) < 1)
      setPageNo(1);
    else
      setPageNo(e.target.value);
  }
  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
    setPageNo(1);
  };

  const handleSelectPlan = (e) => {
    setSelectPlan(e.target.value);
    setPageNo(1);
  }

  const handleSelectSourceCount = (e) => {
    setSourceCount(e.target.value);
    setPageNo(1);
  }

  const handleBotCount = (e) => {
    setBotCount(e.target.value);
    setPageNo(1);
  }

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    setPageNo(1)
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    setPageNo(1)

  };

  const handleUserExport = () => {
    // Replace with your API call to get the file URL
    setLoader(true)
    FetchWithToken(BASE_URL + "/export_users", 'POST', { user_id: user_id })
      .then((data) => {
        setLoader(false)
        if (data.Success) {
          // Open the file download link in a new tab
          window.open(data.Success, '_blank');
        }
      })
      .catch((error) => {
        console.error('Error exporting users:', error.message);
      });
  };

  return (
    <>
      <Flex>
        <Text>Users List</Text>
      </Flex>
      <Flex mt={"10px"} justifyContent={'space-between'}>
        <InputGroup width={"350px"}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            variant="filled"
            value={searchInput}
            onChange={handleSearchInput}
            placeholder="search user by email"
          />
          <Button mx={'3px'} onClick={() => setSearchInput("")}>Clear</Button>
        </InputGroup>
        <Select placeholder='Select plan' mx={2} width={"200px"} variant="filled" value={selectPlan} onChange={handleSelectPlan}>
          <option>Enterprise</option>
          {/* <option>Free</option> */}
          <option>Growth</option>
          <option>Pro</option>
          <option>Starter</option>
        </Select>
        <NumberInput variant="filled" mx={2}>
          <NumberInputField placeholder="Source count" value={sourceCount} onChange={handleSelectSourceCount} />
        </NumberInput>
        <NumberInput variant="filled" mx={2}>
          <NumberInputField placeholder="Bot count" value={botCount} onChange={handleBotCount} />
        </NumberInput>
        <Box display={"flex"} alignItems='center'>
          <Text fontSize='md' color={'gray'}>From  </Text>

          <InputGroup mx={2} >
            <InputLeftElement pointerEvents="none" children={<IoCalendarOutline color="gray.500" />} />
            <Input type='date' onChange={handleStartDateChange}
              value={startDate} placeholder='select date'
              max={currentDate} />
          </InputGroup>

          <Text fontSize='sm' color={'gray'}>To  </Text>
          <InputGroup mx={2} >
            <InputLeftElement pointerEvents="none" children={<IoCalendarOutline color="gray.500" />} />
            <Input type='date' onChange={handleEndDateChange}
              value={endDate} placeholder='select date'
              max={currentDate} />
          </InputGroup>
        </Box>
        <Button bg={"var(--Web-color-orange)"}
          color={"white"}
          _hover={{ bg: "#FF8321" }}
          isLoading={loader}
          leftIcon={<BiExport />}
          onClick={handleUserExport}
        >Export</Button>
      </Flex>
      <Box>
        <TableContainer marginTop={4} maxWidth={"100%"}>
          <Table variant="striped" colorScheme="orange" size="sm" fontSize={"sm"}>
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th>User Email</Th>
                <Th>Created At</Th>
                <Th>Package Type</Th>
                <Th>#Chatbots</Th>
                <Th>#Team-Members</Th>
                {/* <Th>Team-Members Emails</Th> */}
                <Th>#Source Pages</Th>
                {/* <Th>Delete Users</Th> */}
              </Tr>
            </Thead>
            <Tbody height={'300px'} >
              {
                isLoading ? <Box margin={10} textAlign={'center'}> <Spinner /> loading...</Box>
                  :
                  <>
                    {usersList && usersList.length > 0 ? (
                      <>
                        {usersList?.map((user, index) => {
                          return (
                            <Tr key={index}>
                              <Td>{user.user_email}</Td>
                              <Td>{user.account_creation_date}</Td>
                              <Td>{user.package_type}</Td>
                              <Td>{user.chatbot_created}</Td>
                              <Td>{user.team_members}</Td>
                              {/* <Td maxW="200px" isTruncated>
                    gulshank721@gmail.com, gulshank827u@e.cin
                    gulshank721@gmail.com, gulshank827u@e.cin
                  </Td> */}
                              <Td>{user.source_pages}</Td>
                              <Td>
                                <Tooltip hasArrow label="Delete User" bg="red.600">
                                  <IconButton
                                    color="red.400"
                                    variant="outline"
                                    aria-label="Search database"
                                    onClick={() => handleDeleteUser(user.user_email)}
                                    icon={<DeleteIcon />}
                                  />
                                </Tooltip>
                              </Td>
                            </Tr>
                          );
                        })}{" "}
                      </>
                    ) : (
                      <>NO Users Found</>
                    )}
                  </>
              }
            </Tbody>
          </Table>
        </TableContainer>
        <Box
          display={"flex"}
          width={"500px"}
          marginTop={'5px'}
          justifyContent="space-evenly"
          alignItems={"center"}
        >
          <IconButton
            isDisabled={(pageNo <= 1 || isLoading) ? true : false}
            onClick={() => pageChangeButton("left")}
            icon={<ArrowLeftIcon />}
          />

          <Text>
            Page <Input width={'40px'} height={'30px'} padding={1} value={pageNo} onChange={pageNoChange} placeholder={pageNo} /> /{totalPages}
          </Text>

          <IconButton
            isDisabled={(pageNo >= totalPages || isLoading) ? true : false}
            onClick={() => pageChangeButton("right")}
            icon={<ArrowRightIcon />}
          />
        </Box>
      </Box>
      <ShowConfirmationModal
        isOpen={showConfirmModal}
        onClose={handleConfirmationModalClose}
        userEmail={userEmail}
        callback={fetchUsers}
      />
    </>
  );
};
