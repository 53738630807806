import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CSSReset,
  Center,
  ChakraProvider,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  VStack,
  css,
  extendTheme,
} from "@chakra-ui/react";

import {
  MdArrowDropDown,
  MdFileDownload,
  MdRefresh,
  MdOutlinePerson,
  MdOutlineEmail,
  MdOutlinePhone,
} from "react-icons/md";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { IoCalendarOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { CalendarIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import { BsFillChatFill } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import FetchWithToken from "../../utils/FetchWithToken";
// import ReactPaginate from "react-paginate";

export const ChatHistory = ({ isOpen, onClose, botData }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [isLoading, setIsloading] = useState(false);
  const [chats, setChats] = useState([]);
  const [selectedSession, setSelectedSession] = useState();
  const [activeTab, setActiveTab] = useState("Chats");
  const [chatSessions, setChatSessions] = useState([]);

  const itemsPerPage = 10;
  const [selectedPage, setSelectedPage] = useState(0);

  const handlePageChange = (pageIndex) => {
    if(pageIndex >= 0 && pageIndex <= Math.ceil(chatSessions?.length / itemsPerPage) - 1){
    setSelectedPage(pageIndex);
    }
  };

  const paginatedData = chatSessions?.slice(
    selectedPage * itemsPerPage,
    (selectedPage + 1) * itemsPerPage
  );

  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  // we will display the date as DD-MM-YYYY
  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format the date
    const options = {
      weekday: "long",
      //   year: 'numeric',
      //   month: 'long',
      //   day: 'numeric',
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleString("en-US", options);
    // console.log(formattedDate);
    return formattedDate;
  };

  const formatTime = (dateString) => {
    // Convert the date string to a Date object
    const date = new Date(dateString);
    // Format the time
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedTime = date.toLocaleString("en-US", options);
    //   console.log(formattedTime);
    return formattedTime;
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  useEffect(() => {
    const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
    // console.log('selec',selectedDate,typeof(selectedDate));
    let fetchChatUrl =
      BASE_URL +
      "/chatbot/log?bot_id=" +
      botData.bot_id +
      "&start_date=" +
      startDate +
      "&end_date=" +
      endDate;
    // BASE_URL + "/chatbot/log?bot_id=" + botData.bot_id
    if (isOpen) {
      FetchWithToken(fetchChatUrl, "GET").then((data) => {
        setChatSessions(data?.Chatbot_history);
      });
    }
  }, [isOpen, endDate, startDate]);

  

  const handleExport = () => {
    // Perform export functionality
    setIsloading(true);
    FetchWithToken(
      BASE_URL +
        "/chatbot/log/export?bot_id=" +
        botData.bot_id +
        "&session_id=" +
        selectedSession?.session_id,
      "GET"
    ).then((data) => {
      setIsloading(false);
      // Initiate download
      window.open(data?.Success, "_blank");
    });
  };

  const handleSessionClick = (session) => {
    setSelectedSession(session);
    setChats(session?.history);
  };

  const customTheme = extendTheme({
    // styles: {
    //     global: {
    //       "input[type='date']::-webkit-inner-spin-button, input[type='date']::-webkit-calendar-picker-indicator": {
    //         display: "none",
    //         "-webkit-appearance": "none",
    //       },
    //     },
    //   },
    components: {
      Input: {
        variants: {
          outline: {
            field: {
              _hover: { borderColor: "orange.300" },
              _focus: {
                borderColor: "orange.400",
                boxShadow: "0 0 0 1px #cbd5e0",
              },
            },
          },
        },
      },
    },
  });

  return (
    // <div>ChatHistory</div>
    <>
      <ChakraProvider theme={customTheme}>
        <CSSReset />
        <Modal size={"7xl"} isOpen={isOpen}  onClose={() => {
        setActiveTab("Chats")
        onClose();
      }}>
          <ModalOverlay />
          <ModalContent m={10} p={"20px"} pt={"0px"} height={"700px"}>
            <ModalHeader pb={0}>
              <Box
                height={14}
                display={"flex"}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex>
                  <Text>Chat History </Text>
                  {activeTab === "Chats" && (
                    <Box mx={5} display={"flex"} alignItems="center">
                      <Text fontSize="md" color={"gray"}>
                        Date{" "}
                      </Text>
                      <InputGroup mx={2}>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<IoCalendarOutline color="gray.500" />}
                        />

                        <Input
                          type="date"
                          onChange={handleStartDateChange}
                          value={startDate}
                          placeholder="select date"
                          max={currentDate}
                        />
                      </InputGroup>
                      <Text fontSize="sm" color={"gray"}>
                        To{" "}
                      </Text>
                      <InputGroup mx={2}>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<IoCalendarOutline color="gray.500" />}
                        />
                        <Input
                          type="date"
                          onChange={handleEndDateChange}
                          value={endDate}
                          placeholder="select date"
                          max={currentDate}
                        />
                      </InputGroup>
                      {/* <InputGroup>
                  <InputLeftElement  pointerEvents="none" children={<IoCalendarOutline color="gray.500" />} />
                  <DatePicker                 
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="Select a date"
                  dateFormat="yyyy/MM/dd"
                  customInput={
                    <Input
                      type="text"
                      variant="outline"
                      placeholder="Select a date"
                      readOnly
                      icon={<CalendarIcon color="gray.500" />}
                    />
                  }/>
                  <InputRightElement pointerEvents="none" children={<ChevronDownIcon color="gray.500" />} />
                  </InputGroup> */}
                    </Box>
                  )}
                </Flex>
                <Box mr={6}>
                  <Button
                    _hover={{ bg: "#EB6F0D" }}
                    bg="#FF8321"
                    color={"white"}
                    fontWeight="bold"
                    isLoading={isLoading}
                    onClick={handleExport}
                    leftIcon={<MdFileDownload />}
                    variant="solid"
                  >
                    Export
                  </Button>
                </Box>
              </Box>
              <ModalCloseButton mt={3} />
            </ModalHeader>

            <ModalBody p={0}>
              <Flex h={"100%"} borderWidth="1px" borderRadius="lg">
                <Box width={activeTab === "Leads" ? "100%" : "30%"}>
                  <VStack
                    borderWidth="1px"
                    spacing={0}
                    height={"100%"}
                    maxH={"620px"}
                    overflow={"auto"}
                    sx={{
                      "&::-webkit-scrollbar": { width: "3px" },
                      scrollbarWidth: "3px",
                    }}
                  >
                    <Box width="100%" p={4} borderBottomWidth="1px">
                      <Flex justifyContent="space-between">
                        <Text as="b"> All Conversation</Text>
                        {/* <Spacer /> */}
                        {/* <Text color={"orange.300"}>clear all</Text> */}
                      </Flex>
                      <Flex
                        bg="gray.100"
                        p={1}
                        width="fit-content"
                        padding="4px"
                        borderRadius="md"
                      >
                        <Tabs variant="unstyled">
                          <TabList>
                            <Tab
                              _selected={{
                                bg: "white",
                                color: "orange.500",
                                borderRadius: "md",
                              }}
                              onClick={() => setActiveTab("Chats")}
                            >
                              Chats
                            </Tab>
                            <Tab
                              _selected={{
                                bg: "white",
                                color: "orange.500",
                                borderRadius: "md",
                              }}
                              onClick={() => setActiveTab("Leads")}
                            >
                              Leads
                            </Tab>
                          </TabList>
                        </Tabs>
                      </Flex>
                      {/* <InputGroup mt={"10px"}>
                        <InputLeftElement pointerEvents="none">
                          <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                          type="text"
                          variant="filled"
                          placeholder="Search"
                        />
                      </InputGroup> */}
                    </Box>

                    {activeTab === "Chats" &&
                      chatSessions?.map((session, index) => {
                        return (
                          session?.history && (
                            <Box
                              fontSize={"16px"}
                              key={index}
                              onClick={() => handleSessionClick(session)}
                              width="100%"
                              p={4}
                              _hover={{ bg: "orange.50" }}
                              mt={0}
                              borderBottomWidth="1px"
                              bg={
                                session.session_id ===
                                  selectedSession?.session_id && "orange.50"
                              }
                              borderLeftWidth={
                                session.session_id ===
                                  selectedSession?.session_id && "5px"
                              }
                              borderLeftColor={
                                session.session_id ===
                                  selectedSession?.session_id && "orange.300"
                              }
                            >
                              <Flex justifyContent="space-between">
                                <Flex flexDirection="column">
                                  {session?.name ? (
                                    <Flex alignItems="center">
                                      <MdOutlinePerson /> {session.name}
                                    </Flex>
                                  ) : (
                                    `User #${session?.session_id?.substring(
                                      session?.session_id?.length - 4
                                    )}`
                                  )}
                                  {session?.email && (
                                    <Flex alignItems="center">
                                      <MdOutlineEmail /> {session.email}
                                    </Flex>
                                  )}
                                  {session?.phone && (
                                    <Flex alignItems="center">
                                      <MdOutlinePhone /> {session.phone}
                                    </Flex>
                                  )}
                                </Flex>
                                {/* <Spacer /> */}
                                <Text>{formatDate(session?.created_ts)}</Text>
                              </Flex>

                              <Flex justifyContent="space-between">
                                {/* {console.log(session?.history, index)} */}
                                <Center>
                                  bot:{" "}
                                  {session?.history[
                                    session?.history.length - 1
                                  ].bot?.message?.substring(0, 30)}
                                </Center>
                                {/* <Spacer /> */}
                                {/* <Center>Date/Time</Center> */}
                              </Flex>
                            </Box>
                          )
                        );
                      })}
                    {activeTab === "Leads" && (
                      <>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>No.</Th>
                              <Th>Name</Th>
                              <Th>Phone</Th>
                              <Th>Email</Th>
                              <Th>Recorded At</Th>
                            </Tr>
                          </Thead>
                          {paginatedData?.map((item, index) => (
                            <Tr key={index}>
                              <Td>{index + 1 + selectedPage * itemsPerPage}</Td>
                              <Td>{item.name || "-"}</Td>
                              <Td>{item.phone || "-"}</Td>
                              <Td>{item.email || "-"}</Td>
                              <Td>{formatDate(item?.created_ts)}</Td>
                            </Tr>
                          ))}
                        </Table>
                        <Flex justifyContent="center" mt={8}>
                          <Button
                            onClick={() => handlePageChange(selectedPage - 1)}
                            disabled={selectedPage === 0}
                            mr={2}
                          >
                            <ChevronLeftIcon />
                          </Button>
                          <Box>
                            {Array.from(
                              {
                                length: Math.ceil(
                                  chatSessions?.length / itemsPerPage
                                ),
                              },
                              (_, i) => (
                                <Button
                                  key={i}
                                  color={selectedPage === i ? "white" : "black"}
                                  onClick={() => handlePageChange(i)}
                                  variant={
                                    selectedPage === i ? "solid" : "ghost"
                                  }
                                  colorScheme={
                                    selectedPage === i ? "orange" : "gray"
                                  }
                                  mx={1}
                                >
                                  {i + 1}
                                </Button>
                              )
                            )}
                          </Box>
                          <Button
                            onClick={() => handlePageChange(selectedPage + 1)}
                            disabled={
                              selectedPage === 
                              Math.ceil(chatSessions?.length / itemsPerPage) - 1
                            }
                            ml={2}
                          >
                            <ChevronRightIcon />
                          </Button>
                        </Flex>
                      </>
                    )}
                  </VStack>
                </Box>
                {activeTab === "Chats" && (
                  <Box
                    width={"70%"}
                    bg={"gray.50"}
                    justifyContent={"flex-end"}
                    p={4}
                    pr={0}
                    flex="1"
                    display="flex"
                    flexDirection="column"
                  >
                    <Box
                      pr={6}
                      height={"580px"}
                      overflow={"auto"}
                      sx={{
                        "&::-webkit-scrollbar": { width: "3px" },
                        scrollbarWidth: "3px",
                      }}
                    >
                      {/* Example chat messages */}

                      {chats?.map((chat, index) => (
                        <>
                          {chat?.user && (
                            <>
                              <Box
                                width={"fit-content"}
                                maxW={"430px"}
                                // display={"flex"}
                                key={index}
                                alignItems={"center"}
                                justifyContent={"start"}
                                // borderWidth={'1px'}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  pb={1}
                                  gap={1}
                                  fontSize={"10px"}
                                >
                                  <Text>
                                    {chat?.name
                                      ? `${chat?.name}`
                                      : `User #${selectedSession?.session_id?.substring(
                                          selectedSession?.session_id?.length -
                                            4
                                        )}`}
                                  </Text>
                                  <Text px={2}>
                                    {formatTime(chat?.created_ts)}
                                  </Text>
                                </Box>
                                <Box
                                  width={"fit-content"}
                                  maxW={"430px"}
                                  bg={"white"}
                                  borderRadius={" 0 14px 14px 14px"}
                                  p={2}
                                  mb={2}
                                  ml={0}
                                  borderWidth={"1px"}
                                  alignSelf={"flex-start"}
                                  color={"black"}
                                >
                                  <p>{chat?.user?.message}</p>
                                </Box>
                              </Box>

                              {/* <Box
                        width={"fit-content"}
                        maxW={"430px"}
                        bg={"white"}
                        borderRadius={
                            " 0 14px 14px 14px"
                        }
                        p={2}
                        mb={2}
                        ml={ 0}
                        borderWidth={'1px'}
                        // alignSelf={
                        //   "flex-start"
                        // }
                        color={"black"}
                      >
                        <p>{chat.user.message}</p>
                      </Box> */}
                            </>
                          )}

                          {chat?.bot && (
                            <Box display={"flex"} justifyContent={"end"}>
                              <Box
                                // display={"flex"}
                                width={"fit-content"}
                                maxW={"430px"}
                                alignItems={"center"}
                                ml={0}
                                // justifyContent={ 'end'}
                                // borderWidth={'1px'}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  pb={1}
                                  gap={1}
                                  fontSize={"10px"}
                                >
                                  <Text px={2}>
                                    {formatTime(chat?.created_ts)}
                                  </Text>
                                  <Text> bot</Text>
                                </Box>
                                <Box
                                  width={"fit-content"}
                                  maxW={"430px"}
                                  bg={"blue.500"}
                                  // borderWidth={'1px'}
                                  borderRadius={"14px 0px 14px 14px"}
                                  p={2}
                                  mb={2}
                                  ml={"auto"}
                                  alignSelf={"flex-end"}
                                  color={"white"}
                                >
                                  <p>
                                  {chat?.bot?.message.split("\n").map((line) => (
                                    <>
                                      {line}
                                      <br />
                                    </>
                                  ))}
                                  </p>
                                  {/* <p>{chat?.bot?.message}</p> */}
                                </Box>
                              </Box>

                              {/* <Box
                        width={"fit-content"}
                        maxW={"430px"}
                        bg={"blue.500"}
                        // borderWidth={'1px'}
                        borderRadius={
                            "14px 0px 14px 14px"
                        }
                        p={2}
                        mb={2}
                        ml={ 'auto'}
                        alignSelf={
                            "flex-end"
                        }
                        color={"white"}
                      >
                        <p>{chat.bot.message}</p>
                      </Box> */}
                            </Box>
                          )}
                          {/* <Box
                        pb={1}
                        gap={1}
                        display={"flex"}
                        alignItems={"center"}
                        fontSize={"12px"}
                        justifyContent={ 
                          chat.sender === "user" ? "end" : "start"
                        }
                      >
                        {chat.sender === "bot" ? (
                          <>
                            {" "}
                            <Avatar
                              w={4}
                              h={4}
                              bg={"teal"}
                              icon={<BsFillChatFill fontSize={"10px"} />}
                            />
                            Chatbot
                          </>
                        ) : (
                          "You"
                        )}
                      </Box>
                      <Box
                        width={"fit-content"}
                        maxW={"430px"}
                        bg={chat.sender === "bot" ? "white" : "blue.500"}
                        borderRadius={
                          chat.sender === "bot"
                            ? "0px 14px 14px 14px"
                            : "14px 0 14px 14px"
                        }
                        p={2}
                        mb={2}
                        ml={chat.sender === "bot" ? 0 : "auto"}
                        alignSelf={
                          chat.sender === "user" ? "flex-start" : "flex-end"
                        }
                        color={chat.sender === "bot" ? "black" : "white"}
                      >
                        <p>{chat.text}</p>
                      </Box> */}
                        </>
                      ))}
                    </Box>
                  </Box>
                )}
              </Flex>
              {/* <Lore count={2} /> */}
            </ModalBody>

            {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter> */}
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
};
