import {
    Box, CardBody, CardFooter, Card, CardHeader,
    Text, InputGroup, Input, InputRightElement, Button, Heading, Icon, SimpleGrid, Flex, useToast

} from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlineRefresh } from "react-icons/md";
import { AppContext } from "../utils/AppContext";
import { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import FetchWithToken from "../utils/FetchWithToken";
import { t } from "i18next";

const API = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const selectedTeam = JSON.parse(localStorage.getItem("docsbot-users")).selectedTeam;
    const [apikey, setApikey] = useState()
    const navigate = useNavigate();
    const toast = useToast()
    const textRef = useRef(null)

    const team_id = JSON.parse(
        localStorage.getItem("docsbot-users")
    ).selectedTeam.team_id;

    function GenerateAPIkey() {
        const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
        FetchWithToken(BASE_URL + `/api`, 'POST', { user_id: user_id })
            .then(data => {
                console.log(data)
                const usersData = JSON.parse(localStorage.getItem("docsbot-users"));
                usersData.api_key = data.api_key;
                localStorage.setItem("docsbot-users", JSON.stringify(usersData));
                setApikey(data.api_key)
                extractKeyParts(data.api_key);
            });
    }
    const handleCopyApikey = () => {
        navigator.clipboard.writeText(apikey);
        toast({
            title: 'Copied to clipboard',
            status: 'warning',
            duration: 4000,
            isClosable: true,
        })

    };
    const handleCopy = () => {
        if (textRef.current) {
            const textField = document.createElement('textarea');
            textField.innerText = apikey;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            toast({
                title: 'Copied to clipboard',
                status: 'warning',
                duration: 4000,
                isClosable: true,
            })
        }
    };

    const extractKeyParts = (apiKey) => {
        const firstPart = apiKey?.slice(0, 25);
        const lastPart = apiKey?.slice(-25);
        return [firstPart, lastPart];
    };
    const [firstPart, lastPart] = extractKeyParts(apikey);

    useEffect(() => {
        setApikey(JSON.parse(localStorage.getItem("docsbot-users")).api_key)
    }, [apikey]);


    return (
        <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(450px, 1fr))'>
            <Card mt={6} shadow={0} borderRadius="12px">
                <CardBody  >
                    <Heading size="md"> {t("API.HappyChat AI API Key")}</Heading>
                    <Text py="2" fontSize="13px" lineHeight={'150%'}>
                        {t("API.Get your HappyChat AI API key below, which grants access to the admin API and allows queuing private bots. This key is linked to your user account and provides access to all teams where you have a role")}.
                    </Text>
                </CardBody>
                <CardFooter fontSize="13px" justifyContent={'space-between'}>
                    <Flex cursor={'pointer'} color={'#408CFD'} onClick={handleCopy}>
                        {apikey ?
                            <Text ref={textRef} color={'#408CFD'} >
                                API key : {firstPart} . . . {lastPart}
                            </Text> : t("API.No key")}
                    </Flex>
                    <Text onClick={GenerateAPIkey} gap={1} color={'#555555'} _hover={{ color: '#FF8321' }} cursor={'pointer'} display={'flex'} alignItems={'center'}>
                        <MdOutlineRefresh />
                        {t("API.change")}
                        {/* Change */}
                    </Text>
                </CardFooter>
            </Card>

            <Card mt={6} shadow={0} borderRadius="12px">
                <CardBody>
                    <Box>
                        <Heading size="md">{t("API.API Documentation")}</Heading>
                        <Text py="2" fontSize="13px" lineHeight="150%">
                            {t("API.Access the complete")}{' '}
                            <Text cursor={'pointer'} as="span" color={'#408CFD'} textDecoration="underline" onClick={() => window.location.href = '/docs.html'}>
                                {t("API.HappyChat AI API documentation here")}
                            </Text>
                            . {t("API.Utilize the provided API key to interact with the admin API and query private bots. For the admin API and chat APIs, make use of the team ID provided below")}.
                        </Text>
                    </Box>
                </CardBody>
                <CardFooter fontSize="13px" justifyContent={'space-between'}>
                    <Text cursor={'pointer'} color={'#408CFD'}>
                        Team Id: {team_id}
                    </Text>
                    {/* <Text gap={1} color={'#555555'} _hover={{ color: '#FF8321' }} cursor={'pointer'} display={'flex'} alignItems={'center'}>
                        <MdOutlineRefresh />
                        Change
                    </Text> */}
                </CardFooter>
            </Card>
        </SimpleGrid>

        // <Box fontSize={'5rem'} fontWeight={700} display={'flex'} justifyContent={'center'} >
        //     <Box display={'flex'} flexDirection={'column'} alignItems={'baseline'}>
        //         <Text color={'var(--Web-color-orange)'}>
        //             Coming Soon
        //         </Text>
        //         <Button _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
        //         onClick={() => navigate("/admin-dashboard/dashboard")}
        //             variant="outline"
        //             borderRadius={65}
        //             border={"1px solid var(--Web-color-orange)"}
        //             color={'var(--Web-color-orange)'}
        //             p={"6px 15px"}
        //             fontSize={"12px"}
        //             w={'128px'}
        //             h={'33px'}
        //             gap={1}
        //             fontWeight={600} >Dashboard <BsArrowRight /></Button>
        //     </Box>
        // </Box>
    );
};

export default API;