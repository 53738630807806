import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Button, Text, Flex, Link, Select, Input, useToast } from "@chakra-ui/react";
import { RiQuestionnaireLine } from 'react-icons/ri';
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import FetchWithToken from "../../utils/FetchWithToken";

const WhatsAppIntegration = ({ isOpen, onClose, allBotsData, handleCallback }) => {
    const { t, i18n } = useTranslation();

    const BASE_URL = "https://api.happychat.ai/happychat/";

    const initialWhatsAppData = {
        chatBotID: "",
        accountID: "",
        appSecret: "",
        accessToken: ""
    }
    
    const [ whatsAppData, setWhatsAppData ] = useState(initialWhatsAppData);
    const [ verifiedToken, setVerifiedToken ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    
    const toast = useToast();

    const handleWhatsAppChange = (e) => {
        const { name, value } = e.target;
        setWhatsAppData(prev => ({...prev, [name]: value}));
    }

    const handleWhatsAppSubmit = async (e) => {
        e.preventDefault();

        const data = {
            bot_id: whatsAppData.chatBotID,
            WhatsApp_Account_ID: whatsAppData.accountID,
            WhatsApp_App_Secret: whatsAppData.appSecret,
            Page_Access_Token: whatsAppData.accessToken,
        };

        setWhatsAppData(initialWhatsAppData);
        setIsLoading(true);

        FetchWithToken(BASE_URL + "/integration", "POST", data)
        .then(data => {
            setIsLoading(false);

            if (data.message) {
                setVerifiedToken(data.token);
                toast({
                    title: data.message,
                    status: "warning",
                    duration: 4000,
                    isClosable: true,
                });
                handleCallback();
            } 
            else {
                toast({
                    title: data.error,
                    status: "warning",
                    duration: 4000,
                    isClosable: true,
                });
            }
        });
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader p={'25px'} fontSize={'17px'} bg={"#fff9f4"}>
                    <Text as={"b"} fontSize={"20px"}>{t("Integrations.WhatsApp Integration")}</Text>
                    <Text pt={2} pb={2} mb={2} fontSize={'12px'} fontWeight={500}>{t("Integrations.Create a WhatsApp chatbot that’s connected to your ChatBot")}</Text>
                    <Flex gap={5}>
                        <Text fontSize='xs' as={"b"}>{t("Integrations.Log in to")} <Link color={"#FF8321"} textDecoration={'underline'}>{t("Integrations.Facebook for Developers")}</Link> {t("Integrations.and paste your Account ID, App secret and Access token below")}</Text>
                        <Button borderColor={"#bab5b2"} w={"250px"} h={"30px"} fontSize={"12px"} borderRadius={50} variant={'outline'} rightIcon={<RiQuestionnaireLine />}>{t("Integrations.Step-by-step guide")}</Button>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleWhatsAppSubmit}>
                    <ModalBody>
                        <Text as={"b"} fontSize={"18px"}>{t("Integrations.Your Verify Token")}{verifiedToken ? verifiedToken : "123456789"}</Text>
                        <Text fontSize={"13px"} mt={1}>{t("Integrations.Use this Verify Token when configuring your")} <Link color={"#FF8321"} as={"b"} textDecoration={'underline'}>{t("Integrations.WhatsApp app")}</Link></Text>

                        <FormControl mt={8}>
                            <FormLabel fontSize={"14px"} fontWeight={"bold"}>{t("Integrations.Chatbot")}</FormLabel>
                            <Select name="chatBotID" value={whatsAppData.chatBotID} onChange={handleWhatsAppChange} fontSize={"13px"} placeholder={t("Integrations.Select Chatbot")}>
                                {
                                    allBotsData.map((botData) => (
                                        <option key={botData.bot_id} value={botData.bot_id}>{botData.bot_name}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <FormControl mt={5}>
                            <FormLabel fontSize={"14px"} fontWeight={"bold"}>{t("Integrations.WhatsApp Account ID")}</FormLabel>
                            <Input name="accountID" value={whatsAppData.accountID} onChange={handleWhatsAppChange} fontSize={"12px"} placeholder={t("Integrations.e.g. 123456789098765")} />
                        </FormControl>

                        <FormControl mt={5}>
                            <FormLabel fontSize={"14px"} fontWeight={"bold"}>{t("Integrations.WhatsApp App Secret")}</FormLabel>
                            <Input name="appSecret" value={whatsAppData.appSecret} onChange={handleWhatsAppChange} fontSize={"12px"} placeholder={t("Integrations.e.g. 123456789098765")} />
                        </FormControl>

                        <FormControl mt={5}>
                            <FormLabel fontSize={"14px"} fontWeight={"bold"}>{t("Integrations.Page Access Token")}</FormLabel>
                            <Input name="accessToken" value={whatsAppData.accessToken} onChange={handleWhatsAppChange} fontSize={"12px"} placeholder={t("Integrations.e.g. 123456789098765")} />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter display={'flex'} alignItems={"center"} justifyContent={"center"} gap={5}>
                        <Button h={8} w={'150px'} borderColor={'blackAlpha.500'} borderRadius={50} onClick={onClose} variant={'outline'}>{t("Integrations.Cancel")}</Button>
                        <Button h={8} _hover={{ bg: 'var(--Web-color-orange)' }} fontWeight={400} w={'150px'} borderRadius={50} type='submit' bg={'var(--Web-color-orange)'} color={'var(--Web-color-white)'} mr={3} isLoading={isLoading}>
                        {t("Integrations.Integrate")}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );

};

export default WhatsAppIntegration;