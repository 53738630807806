import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

import logoDark from "../assets/logoDark.png";
import { AppContext } from "../utils/AppContext";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import analyticsService from "../utils/analyticsService";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

const SignUp = () => {
  const { t, i18n } = useTranslation();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const VERIFIED_EMAIL_URL = process.env.REACT_APP_VERIFIED_EMAIL_URL;
  const VERIFIED_EMAIL_API_KEY = process.env.REACT_APP_VERIFIED_EMAIL_API_KEY;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [team_id, setTeamId] = useState(null);
  const [rediect, setRedirect] = useState("dashboard");
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const [urlParams, seturlParams] = useState(
    queryString.parse(window.location.search)
  );
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  // const { globalTeam, setGlobalTeam } = useContext(AppContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("redirect");

    let teamId = null;

    if (redirect) {
      const teamIdParam = new URLSearchParams(redirect.split("?")[1]);
      setTeamId(teamIdParam.get("team_id"));
    }
  }, []);

  const verifyEmail = async (email) => {
    setIsEmailLoading(true);
    try {
      const response = await fetch(
        `${VERIFIED_EMAIL_URL}/verify?email=${encodeURIComponent(
          email
        )}&api_key=${VERIFIED_EMAIL_API_KEY}`
      );
      const data = await response.json();
      setIsEmailLoading(false);

      if (data?.state === "deliverable") {
        setIsValidEmail(true);
        // toast({
        //     title: 'Entered email is valid.',
        //     status: 'success',
        //     duration: 3000,
        //     isClosable: true,
        // })
      } else {
        setIsValidEmail(false);
        // toast({
        //     title: 'Entered email is invalid.',
        //     status: 'warning',
        //     duration: 3000,
        //     isClosable: true,
        // })
      }
    } catch (error) {
      setIsEmailLoading(false);
      console.error("Error verifying email:", error);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    clearTimeout(typingTimeout);

    setIsEmailLoading(true);
    setTypingTimeout(
      setTimeout(() => {
        verifyEmail(newEmail);
      }, 1000)
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      alert("Required all fields");
      return;
    }
    if (password.length < 8 || password.length > 15) {
      setIsPasswordValid(false);
      return; // Prevent form submission if password is not valid
    }

    if (isValidEmail) {
      let signupData = {
        email: email,
        password: password,
        provider: "email",
        user_role: team_id ? "admin" : "owner",
      };
      if (team_id) {
        signupData["team_id"] = team_id;
      }
      setisLoading(true);
      fetch(BASE_URL + "/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signupData),
      })
        .then((response) => response.json())
        .then((data) => {
          setisLoading(false);
          if (data.message) {
            // console.log(data);
            if (urlParams.lang) {
              navigate(`/verify-email?lang=${urlParams.lang}`, {
                state: { email: email },
              });
            } else {
              navigate(`/verify-email`, { state: { email: email } });
            }
            // sending signup event to gtm
            let team;
            if (team_id) {
              team = data.team_details.filter(
                (item) => item.team_id === team_id
              );
            } else {
              team = data.team_details.filter(
                (item) => item.user_role === "owner"
              );
            }

            const now = new Date();
            // Format the date and time as a string in 'YYYY-MM-DD HH:mm:ss' format
            const formattedDate = `${now.getFullYear()}-${String(
              now.getMonth() + 1
            ).padStart(2, "0")}-${String(now.getDate()).padStart(
              2,
              "0"
            )} ${String(now.getHours()).padStart(2, "0")}:${String(
              now.getMinutes()
            ).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;

            console.log(team, formattedDate);
            analyticsService.trackEventInProduction("signup", {
              email: data.email,
              team_name: team[0].team_name,
              login_method: "form",
              user_role: signupData.user_role,
              signup_date: formattedDate,
              user_id: data.user_id,
              user_name: "",
            });
          } else {
            toast({
              title: data.error,
              status: "info",
              duration: 4000,
              isClosable: true,
            });
            setisLoading(false);
          }
        })
        .catch((err) => {
          setisLoading(false);
          toast({
            title: "An error occurred. Please try again later.",
            status: "warning",
            duration: 4000,
            isClosable: true,
          });
          console.log(err);
        });
    }
    // else {
    //     toast({
    //         title: 'Please enter a valid email.',
    //         status: 'warning',
    //         duration: 3000,
    //         isClosable: true,
    //     })
    // }
  };

  const handleAuthSignup = async (credential, provider) => {
    let signupData = {
      token: credential,
      provider: provider,
      user_role: team_id ? "admin" : "owner",
    };

    if (team_id) {
      signupData["team_id"] = team_id;
    }
    // console.log(signupData)
    setisLoading(true);
    fetch(BASE_URL + "/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signupData),
    })
      .then((response) => response.json())
      .then((data) => {
        setisLoading(false);
        if (data.message) {
          if (urlParams.lang) {
            navigate(`/verify-email?lang=${urlParams.lang}`);
          } else {
            navigate(`/verify-email`);
          }
          // sending signup event to gtm
          let team;
          if (team_id) {
            team = data.team_details.filter((item) => item.team_id === team_id);
          } else {
            team = data.team_details.filter(
              (item) => item.user_role === "owner"
            );
          }

          const now = new Date();
          // Format the date and time as a string in 'YYYY-MM-DD HH:mm:ss' format
          const formattedDate = `${now.getFullYear()}-${String(
            now.getMonth() + 1
          ).padStart(2, "0")}-${String(now.getDate()).padStart(
            2,
            "0"
          )} ${String(now.getHours()).padStart(2, "0")}:${String(
            now.getMinutes()
          ).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;

          // console.log(team, formattedDate);
          analyticsService.trackEventInProduction("signup", {
            email: data.email,
            team_name: team[0].team_name,
            login_method: "google",
            user_role: signupData.user_role,
            signup_date: formattedDate,
            user_id: data.user_id,
            user_name: "",
          });
        } else {
          toast({
            title: data.error,
            status: "info",
            duration: 4000,
            isClosable: true,
          });
          setisLoading(false);
        }
      })
      .catch((err) => {
        setisLoading(false);
        toast({
          title: "An error occurred. Please try again later.",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
        console.log(err);
      });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword.length < 8 || newPassword.length > 15) {
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }
  };

  const linkStyles = {
    display: "inline-block",
    padding: "1px 5px",
    color: "#FF8321",
    textDecoration: "none",
    fontWeight: "500",
    transition: "background-color 0.3s ease",
  };

  function handleMouseEnter(event) {
    event.target.style.textDecoration = "underline";
  }

  function handleMouseLeave(event) {
    event.target.style.textDecoration = "none";
  }

  function loginLanguage() {
    if (urlParams.lang) {
      return `/login?lang=${urlParams.lang}`;
    } else {
      return "/login";
    }
  }

  useEffect(() => {
    seturlParams(queryString.parse(window.location.search));
    i18n.changeLanguage(urlParams.lang ? urlParams.lang : "en");
    // localStorage.setItem("language", lang);
  }, []);

  return (
    <Flex minHeight="100vh" width="full" align="center" pt={3}>
      <Box width="full" align="center">
        <Image src={logoDark} alt="logodark" h={"40px"} />
        <Box
          my={4}
          p={8}
          maxWidth="450px"
          borderWidth={1}
          borderRadius={10}
          boxShadow="lg"
        >
          {/* <Image src="https://docsbot.ai/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdocsbot-logo.c6071a27.png&w=256&q=75" alt="Logo" mb={4} /> */}
          {/* <Image

                        src={logoDark}
                        alt='logodark'
                        h={'40px'}
                        // w={'20px'}
                    /> */}
          <Box>
            <Heading as="h3" size="lg">
              {t("Create your free account")}
            </Heading>
            <Text mt={3} fontSize={"14px"} fontWeight={500}>
              {t("Get started -  it's free. No credit card needed")}
            </Text>
            <Box mt={3}>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleAuthSignup(credentialResponse.credential, "google");
                }}
                onError={() => {
                  toast({
                    title: "Sign Up Failed",
                    status: "warning",
                    duration: 4000,
                    isClosable: true,
                  });
                }}
                text="signup_with"
                useOneTap
              />
            </Box>
          </Box>
          <Flex align="center" justify="center" my={6}>
            <Divider w="40%" borderColor="#bdbdbd" />
            <Text mx={4} fontWeight="bold" color="#bdbdbd">
              or
            </Text>
            <Divider w="40%" borderColor="#bdbdbd" />
          </Flex>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>{t("email_address")}</FormLabel>
              <InputGroup>
                <Input
                  type="email"
                  mb={3}
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                {email && (
                  <InputRightElement>
                    {isEmailLoading ? (
                      <Spinner w={4} h={4} color="gray.400" />
                    ) : isValidEmail ? (
                      <CheckIcon
                        w={5}
                        h={5}
                        color="white"
                        bg={"green"}
                        borderRadius={"full"}
                        p={1}
                      />
                    ) : (
                      <CloseIcon
                        w={5}
                        h={5}
                        color="white"
                        bg={"red"}
                        borderRadius={"full"}
                        p={1}
                      />
                    )}
                  </InputRightElement>
                )}
              </InputGroup>

              <FormLabel>{t("password")}</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                required
                isInvalid={!isPasswordValid}
                // minLength={8}
                // maxLength={15}
              />

              {!isPasswordValid && (
                <Text textAlign={"start"} color="red" fontSize="sm" mt={0}>
                  {t("Password must be 8 to 15 characters long")}
                </Text>
              )}

              <Button
                isLoading={isLoading}
                mt={4}
                w={"100%"}
                _hover={{ bg: "#EB6F0D" }}
                color={"white"}
                bg={"#FF8321"}
                type="submit"
                align="right"
              >
                Create account
              </Button>
              <Flex justifyContent="center" mt={4}>
                <Text ml={2} fontSize="sm">
                  {t("By creating an account you agree to")}
                  <br />
                  <Link
                    style={linkStyles}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    textDecoration="underline"
                    to="https://happychat.ai/terms"
                    target="_blank"
                  >
                    {t("Terms of Service")}
                  </Link>
                  {t("and")}
                  <Link
                    style={linkStyles}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    textDecoration="underline"
                    to="https://happychat.ai/privacy"
                    target="_blank"
                  >
                    {t("Privacy Policy")}
                  </Link>
                </Text>
              </Flex>
              <Flex justifyContent={"center"} mt={5} alignItems={"center"}>
                <Text>{t("Already have an Account?")} </Text>
                <Link
                  style={linkStyles}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  to={loginLanguage()}
                >
                  {t("Sign In")}
                </Link>
              </Flex>
            </FormControl>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default SignUp;
