// analyticsService.js
const mode = process.env.REACT_APP_MODE;

const analyticsService = {
  trackEvent: (eventName, eventData) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...eventData,
    });
  },
  trackEventInProduction: (eventName, eventData) => {
    if (mode === "production") {
      try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: eventName,
          ...eventData,
        });
      } catch (error) {
        console.error("error in SignUp event.",error);
      }
    } else {
      console.log("Cannot track in Development or Testing mode.", mode);
    }
  },
};

export default analyticsService;
