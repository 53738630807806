import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Heading,
  AvatarBadge,
  Avatar,
  InputRightElement,
  InputGroup,
  TagLabel,
  Tag,
  FormLabel,
  Button,
  Flex,
  FormControl,
  CloseButton,
  useToast,
} from "@chakra-ui/react";
import { BsFillChatFill } from "react-icons/bs";
import { BeatLoader } from "react-spinners";

import { RiSendPlane2Fill } from "react-icons/ri";
import FetchWithToken from "../../utils/FetchWithToken";
import { IoClose } from "react-icons/io5";
import { MdCancel, MdCheckCircle, MdInfo } from "react-icons/md";
import { cleanup } from "@testing-library/react";

const LeadForm = ({
  LeadsData,
  sessionId,
  botData,
  handleClose,
  setLeadsFlag,
  setFormSubmitted,
  formSubmitted,
}) => {
  const toast = useToast();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [step, setStep] = useState(0);
  const [isLoading, setisloading] = useState(false);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const initialFields = [];

    if (LeadsData) {
      if (LeadsData["name"]) {
        initialFields.push({ label: "Name", value: "" });
      }
      if (LeadsData["email"]) {
        initialFields.push({ label: "Email", value: "" });
      }
      if (LeadsData["phone"]) {
        initialFields.push({ label: "Phone", value: "" });
      }
    }

    setFields(initialFields);
  }, [LeadsData]);

  const toastIdRef = React.useRef();
  function closeToast() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  const showToast = (description) => {
    toastIdRef.current = toast({
      isUsingPortal: true,
      position: "bottom",
      render: () => (
        <Flex
          gap={2}
          justifyContent="space-between"
          borderRadius="10px"
          color="white"
          p={3}
          bg="blackAlpha.700"
          zIndex={9999} // Set the z-index value here
        >
          <Box display="flex" gap={2}>
            <Box>
              <Text color={"whiteAlpha.700"} fontSize={"15px"} fontWeight={500}>
                {description}
              </Text>
            </Box>
          </Box>

          <IoClose
            onClick={closeToast}
            color={"RGBA(255, 255, 255, 0.60)"}
            size={20}
            cursor={"pointer"}
          />
        </Flex>
      ),
    });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleInputChange = (value, index) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field, i) => {
        if (i === index) {
          const isPhoneField = field.label.toLowerCase().includes('phone');
          let intValue = isPhoneField ? parseInt(value.replace(/\D/g, ''), 10) : value;
          if (isPhoneField) {
            intValue = isNaN(intValue) ? '' : Math.abs(intValue).toString().slice(0, 10);
          }
  
          return { ...field, value: intValue };
        } else {
          return field;
        }
      });
      return updatedFields;
    });
  };
  
  
  
  
  
  const handleNext = () => {
    const currentField = fields[step];
    if (currentField) {
      const fieldValue = currentField?.value;
      const fieldLabel = currentField?.label;

      if (fieldLabel === "Email" && fieldValue == "") {
        return;
      }
      if (fieldLabel === "Email" && !validateEmail(fieldValue)) {
        setTimeout(() => {
          showToast("Enter a valid email address");
        }, 2000);
        return;
      }
      if (fieldValue == "") {
        return;
      }
      if (step < fields?.length - 1) {
        setStep((prevStep) => prevStep + 1);
      } else if (step === fields?.length - 1) {
        handleSubmit();
      }
    }
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 700,
    fontSize: '13px',
    fontFamily: 'Satoshi, sans-serif',
  };
  

  const handleSubmit = () => {
    if (step + 1 === fields.length) {
      const nameField = fields.find((field) => field.label === "Name");
      const emailField = fields.find((field) => field.label === "Email");
      const phoneField = fields.find((field) => field.label === "Phone");

      if (nameField && nameField?.value == "") {
        return;
      }
      if (emailField && emailField?.value == "") {
        return;
      }
      if (emailField && !validateEmail(emailField?.value)) {
        setTimeout(() => {
          showToast("Enter valid email address");
        }, 2000);
        return;
      }
      if (phoneField && phoneField?.value == "") {
        return;
      }

      const formData = {
        name: nameField?.value || "",
        email: emailField?.value || "",
        phone: phoneField?.value || "",
        session_id: sessionId,
        bot_id: botData?.bot_id,
      };
      setisloading(true);
      FetchWithToken(BASE_URL + "/add-leads", "POST", formData).then((data) => {
        console.log(data);
        setisloading(false);
        setFormSubmitted(true);
      });
    }
  };
  return (
    <Box
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        borderRadius: "12.48px",
      }}
    >
      <Flex justifyContent="flex-end" width="100%">
        <CloseButton
          onClick={handleClose}
          position="justify-end"
          mt="1"
          mr="1"
          size="sm"
          colorScheme="blue"
        />
      </Flex>
      <Text
        style={{
          fontWeight: 700,
          fontSize: "13px",
          fontFamily: "Satoshi, sans-serif",
        }}
      >
        {LeadsData && LeadsData["lead_id"]
          ? LeadsData["message"]
          : "No message available"}
      </Text>

      {!formSubmitted ? (
        <>
          {fields?.slice(0, step + 1).map((field, index) => (
            <FormControl key={index}>
            <FormLabel style={labelStyle}>{field?.label}</FormLabel>
              <Input
                type="text"
                placeholder={`Enter ${field?.label}`}
                value={field?.value}
                _focus={{ outline: "none", boxShadow: "0 0 0 2px #FF8321" }}
                onChange={(e) => handleInputChange(e.target.value, index)}
                onFocus={(e) => (e.target.style.borderColor = "#FF8321")}
                onBlur={(e) => (e.target.style.borderColor = "")}
              />
            </FormControl>
          ))}

          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "10px" }}
          >
            <Text
              style={{
                fontFamily: "Satoshi, sans-serif",
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              {step + 1} of {fields?.length}
            </Text>
            <Button
                w={"70px"}
                h={"33px"}
                fontSize={"14px"}
                _hover={{ bg: "#f97913" }}
                isLoading={isLoading}
                type="submit"
                borderRadius={"65px"}
                variant="solid"
                bg={"var(--Web-color-orange)"}
                color={"white"}
              onClick={step === fields?.length - 1 ? handleSubmit : handleNext}
            >
              {step === fields?.length - 1 ? "Submit" : "Next"}
            </Button>
          </Flex>
        </>
      ) : (
        <Flex flexDirection="column" alignItems="center">
          <Flex
            width="20px"
            height="20px"
            borderRadius="50%"
            backgroundColor="#4CB71A"
            justifyContent="center"
            alignItems="center"
            margin="20px auto 8px auto"
            position="relative"
          >
            <svg
              width="12"
              height="6"
              viewBox="0 0 6 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.878906 1.81641L2.53125 3.46875L5.79297 0.207031"
                stroke="white"
                strokeWidth="1.7"
              />
            </svg>
          </Flex>
          <Text
            textAlign="center"
            fontWeight="700"
            color="#4CB71A"
            fontSize="15px"
          >
            Form submitted successfully!
          </Text>
        </Flex>
      )}
    </Box>
  );
};

const AskQuestion = ({ isOpen, onClose, botData, question_role }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [firstQuestion, setFirstQuestion] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [widgetData, setWidgetData] = useState("");
  const [leadsFlag, setLeadsFlag] = useState(false);
  const [messageCount, setMessageCount] = useState(1);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [eventover,setEventOver] = useState(false);
  // const [eventData, setEventData] = useState([]);

  useEffect(() => {
    if (leadsData && leadsData["collect_leads_at_start"] && !formSubmitted) {
      setLeadsFlag(true);
    }
  }, [leadsData, formSubmitted]);

  const handleLeadsCall = () => {
    if (!leadsFlag && !formSubmitted && leadsData) {
      if (messageCount === parseInt(leadsData["collect_leads_after_message"])) {
        setLeadsFlag(true);
      }
    }
  };
  const sessionId = localStorage.getItem("sessionId");

  const handleClose = () => {

    if (formSubmitted) {
      setLeadsFlag(false);
    }
  };

  // Define eventSource outside of the handleMessageSubmit function
  // let eventSource;
  const [eventSource, setEventSource] = useState(null);
  const cleanupEventSource = () => {
    console.log("eventSource cleanup", eventSource);
    if (eventSource) {
      eventSource.close();
      setEventSource(null);
    }
  };
  
  const handleMessageSubmit = async () => {
    setMessageCount(messageCount + 1);
    if(leadsFlag){
      setLeadsFlag(!leadsFlag)
  }
    if (message) {
      setChatMessages((prevChatMessages) => [
        ...prevChatMessages,
        { text: message, sender: "user" },
      ]);
      setMessage("");
      handleLeadsCall();
      setisLoading(true);
      try {
        const user_id = JSON.parse(
          localStorage.getItem("docsbot-users")
        ).user_id;
        // const askData = {
        //     user_id: user_id,
        //     query: message,
        //     bot_id: botData.bot_id,
        //     role: question_role,
        //     session_id: sessionId
        // }

        // FetchWithToken(BASE_URL + '/chatbot/ask_question', 'POST', askData)
        //     .then(data => {
        //         setisLoading(false)
        //         setChatMessages(prevChatMessages => [
        //             ...prevChatMessages,
        //             { text: data.message, sender: 'bot' }
        //         ]);
        //     });
        // Close the existing event source before creating a new one
        cleanupEventSource();
        const eventSource = new EventSource(
        BASE_URL +
          "/chatbot/ask_question?user_id=" +
          user_id +
          "&query=" +
          message +
          "&bot_id=" +
          botData.bot_id +
          "&role=" +
          question_role +
          "&session_id=" +
          sessionId
        );
        // let accumulatedData = '';
        console.log("after createing", eventSource);
        let messageBoxLength = 0;
        eventSource.addEventListener("open", (event) => {
          setisLoading(false);
          setChatMessages((prevChatMessages) => [
            ...prevChatMessages,
            { text: "", sender: "bot" },
          ]);
          messageBoxLength = chatMessages.length + 1;
        });

        setEventSource(eventSource);

        eventSource.addEventListener("message", (event) => {
          setEventOver(true);
          const newData = event.data;
          const validJsonString = newData.replace(/'([^']*)'/g, '"$1"'); //before it was ([^']+)
          let valueOfWe = "";
          try {
            const jsonData = JSON.parse(validJsonString);
            valueOfWe = jsonData.data;
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
          if (valueOfWe === "###finish###") {
            // setEventData([]);
            setEventOver(false);
            eventSource.close();
            // accumulatedData = '';

            if (widgetData["citations"] === "true") {
              FetchWithToken(
                BASE_URL + "/chatbot/citation?session_id=" + sessionId,
                "GET"
              ).then((data) => {
                console.log(data);
                setChatMessages((prevChatMessages) => {
                  const updatedChatMessages = [...prevChatMessages];
                  if (updatedChatMessages.length >= messageBoxLength) {
                    updatedChatMessages[messageBoxLength] = {
                      ...updatedChatMessages[messageBoxLength],
                      source: data.source_documents,
                    };
                  }
                  return updatedChatMessages;
                });
              });
            }

            return;
          }
          // setEventData((prevData) => [...prevData, valueOfWe]);
          // accumulatedData += valueOfWe
          setChatMessages((prevChatMessages) => {
            const updatedChatMessages = [...prevChatMessages];
            if (updatedChatMessages.length >= messageBoxLength) {
              updatedChatMessages[messageBoxLength] = {
                ...updatedChatMessages[messageBoxLength],
                text: updatedChatMessages[messageBoxLength]?.text + valueOfWe,
              };
            }
            return updatedChatMessages;
          });
          // Clear the input field
          setMessage("");
        });
       
      } catch (error) {
        setisLoading(false);
        console.log("Error:", error);
      }
    }
  };

  useEffect(()=>{
    return () => {
      setMessage("");
      cleanupEventSource();
       // Cleanup event source when the component is unmounted
    };
  },[isOpen])

  const [showItems, setShowItems] = useState();

  const toggleItems = (MIndex) => {
    // setShowItems((prevShowItems) => !prevShowItems);
    if (showItems === MIndex) {
      setShowItems(null);
    } else {
      setShowItems(MIndex);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleMessageSubmit();
    }
  };

  const handleFirstQuestion = (question) => {
    setMessage(question);
  };

  const modalBodyRef = useRef(null);
  useEffect(() => {
    // Scroll to the last message box when messages change
    if (modalBodyRef.current) {
      const modalBodyElement = modalBodyRef.current;
      modalBodyElement.scrollTop = modalBodyElement.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    if (isOpen) {
      FetchWithToken(
        BASE_URL + "/chatbot/questions?bot_id=" + botData.bot_id,
        "GET"
      ).then((data) => {
        setFirstQuestion(data?.quesions);
      });
      FetchWithToken(
        BASE_URL + "/get-bot-leads?bot_id=" + botData.bot_id,
        "GET"
      ).then((data) => {
        setLeadsData(data?.settings[0]);
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const generateSessionId = () => {
        const randomString = Math.random().toString(36).substring(2, 8);
        const timestamp = Date.now().toString(36); 
        return randomString + timestamp;
      };

      const session_Id = generateSessionId();
      localStorage.setItem("sessionId", session_Id);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      FetchWithToken(BASE_URL + "/widget?bot_id=" + botData.bot_id, "GET").then(
        (data) => {
          setWidgetData(data?.widget);
        }
      );
    }
  }, [isOpen]);

  const isdisable = leadsFlag ? (formSubmitted ? false : true) : false;


  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={() => {
        cleanupEventSource();
        setChatMessages([]);
        setMessageCount(1);
        setFormSubmitted(false);
        setLeadsData([]);
        setLeadsFlag(false);
        setisLoading(false);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent marginTop={5} height="xl" pr={0} borderRadius={"15px"}>
        <ModalHeader
          borderRadius={"15px  15px   0px 0px"}
          p={"15px"}
          bg={"#408CFD"}
          boxShadow={"0px 10.3621px 28.695px -6.37666px rgba(0, 0, 0, 0.25)"}
        >
          <Box display={"flex"} gap={4}>
            <Avatar
              padding={2.5}
              position="relative"
              icon={<BsFillChatFill fontSize="1.7rem" />}
            >
              <AvatarBadge
                boxSize="0.6em"
                bg="green.500"
                position="absolute"
                top="90%"
                right="1"
                transform="translateY(-50%)"
              />
            </Avatar>
            <Box>
              <Heading color={"white"} fontSize="28px" size="sm">
                {botData?.bot_name &&
                  botData?.bot_name.charAt(0).toUpperCase() +
                    botData?.bot_name.slice(1)}
              </Heading>
              <Text fontSize={"13px"} fontWeight={400} ml="1" color={"white"}>
                Online
              </Text>
            </Box>
          </Box>
          {/* <Text py="2" fontSize="15px">{botData?.description && botData?.description.charAt(0).toUpperCase() + botData?.description.slice(1)}</Text> */}
          <ModalCloseButton color={"white"} />
        </ModalHeader>

        <ModalBody
          ref={modalBodyRef}
          pl={0}
          pr={0}
          overflow={"auto"}
          bg="#E7EBF1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            justifyContent={"flex-start"}
            p={4}
            pr={0}
            borderRadius="md"
            flex="1"
            display="flex"
            flexDirection="column"
          >
            {/* Chat messages */}
            <Box pr={6}>
              {widgetData["first-message"] && (
                <Box
                  width={"fit-content"}
                  maxW={"350px"}
                  bg={"white"}
                  borderRadius={"14px 14px 14px 0px"}
                  p={2}
                  mb={2}
                  ml={0}
                  alignSelf={"flex-end"}
                  color={"black"}
                >
                  <p>{widgetData["first-message"]}</p>
                </Box>
              )}
              {leadsData &&
                leadsFlag &&
                leadsData["collect_leads_at_start"] && (
                  <>
                    <LeadForm
                      LeadsData={leadsData}
                      sessionId={sessionId}
                      setFormSubmitted={setFormSubmitted}
                      botData={botData}
                      setLeadsFlag={setLeadsFlag}
                      handleClose={handleClose}
                      formSubmitted={formSubmitted}
                    />
                  </>
                )}
              {firstQuestion &&
                !leadsFlag &&
                firstQuestion?.map((quesion, index) => (
                  <Box
                    alignItems={"center"}
                    display={chatMessages?.length > 0 ? "none" : "flex"}
                    cursor={"pointer"}
                  >
                    <Tag
                      bg={"white"}
                      width={"fit-content"}
                      maxW={"350px"}
                      borderRadius={"165.633px"}
                      border={"1.07379px solid #408CFD"}
                      p={2}
                      mb={2}
                      ml={"auto"}
                      alignSelf={"flex-start"}
                      onClick={() => handleFirstQuestion(quesion.question)}
                      color={"#408CFD"}
                    >
                      <TagLabel>{quesion.question}</TagLabel>
                    </Tag>
                  </Box>
                ))}
              {chatMessages?.map((chat, MIndex) => (
                <>
                  {/* <Box
                                        pb={1}
                                        gap={1}
                                        display={'flex'}
                                        alignItems={'center'}
                                        fontSize={'12px'}
                                        justifyContent={chat.sender === 'user' ? 'end' : 'start'}>
                                        {chat.sender === 'bot' ? <><Avatar size={'xs'} bg="white" padding={1.5} position="relative">
                                            <img src={ChatbotLogo} alt="Chatbot Logo" />
                                        </Avatar>HappyChat AI</> : "You"}</Box> */}
                  <Box
                    width={"fit-content"}
                    maxW={"350px"}
                    bg={chat?.sender === "bot" ? "white" : "blue.500"}
                    borderRadius={
                      chat?.sender === "bot"
                        ? "14px 14px 14px 0px"
                        : "14px 14px 0px 14px"
                    }
                    p={2}
                    mb={2}
                    ml={chat?.sender === "bot" ? 0 : "auto"}
                    alignSelf={
                      chat?.sender === "user" ? "flex-start" : "flex-end"
                    }
                    color={chat?.sender === "bot" ? "black" : "white"}
                  >
                    <p>
                      {chat?.text.split("\n").map((line) => (
                        <>
                          {line}
                          <br />
                        </>
                      ))}
                    </p>

                    {chat?.source && (
                      <>
                        <div
                          onClick={() => toggleItems(MIndex)}
                          style={{ cursor: "pointer" }}
                        >
                          {showItems === MIndex ? "▲ Sources" : "▼ Sources"}
                        </div>
                        {showItems === MIndex && (
                          <div>
                            {chat.source.map((item, index) => (
                              <p key={index} style={{ margin: 0 }}>
                                <a
                                  style={{
                                    color: "blue",
                                    textDecoration: "none",
                                    transition: "color 0.3s",
                                  }}
                                  href={item}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item}
                                </a>
                              </p>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </Box>
                </>
              ))}
              {isLoading && (
                <Box
                  width={"fit-content"}
                  maxW={"430px"}
                  bg={"white"}
                  borderRadius={"0px 14px 14px 14px"}
                  p={2}
                  mb={2}
                  ml={0}
                  alignSelf={"flex-end"}
                  color={"black"}
                >
                  <BeatLoader size={6} color="black" />
                </Box>
              )}
              {leadsData &&
                leadsFlag &&
                !isLoading && !eventover &&
                !leadsData["collect_leads_at_start"] && (
                  <>
                    <LeadForm
                      LeadsData={leadsData}
                      sessionId={sessionId}
                      setFormSubmitted={setFormSubmitted}
                      botData={botData}
                      setLeadsFlag={setLeadsFlag}
                      handleClose={handleClose}
                      formSubmitted={formSubmitted}
                    />
                  </>
                )}
            </Box>
          </Box>
        </ModalBody>
        <Box borderRadius={" 0 0 12px 12px"} pt={2} pb={2}>
          <InputGroup size="md" pb={2} borderBottom={"1px solid lightgray"}>
            <Input
              disabled={isLoading || isdisable}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type your message..."
              border={"none"}
              flex="1"
              bg={"white"}
              borderRadius={0}
              pr={"50px"}
              _focus={{ boxShadow: "none" }}
              _hover={{ boxShadow: "none" }}
              _active={{ boxShadow: "none" }}
            />
            <InputRightElement
              width="4.5rem"
              color={leadsFlag ? "gray" : "#D2D2D2"}
              pointerEvents={leadsFlag ? "none" : "auto"}
            >
              <RiSendPlane2Fill onClick={handleMessageSubmit} size={20} />
            </InputRightElement>
          </InputGroup>

          <Box
            pt={"3px"}
            pb={"3px"}
            mt={2}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            g
            fontSize={"12px"}
            color={"gray.500"}
            fontWeight={500}
            gap={1}
          >
            <Text>Powered by</Text>
            {/* <HiChatBubbleLeftRight /> */}
            <Text color={"black"} fontWeight={700}>
              HappyChat
            </Text>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default AskQuestion;
