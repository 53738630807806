import "./App.css";
import { BrowserRouter, Navigate, Route, Routes, Redirect } from "react-router-dom";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";


import AdminDashboard from "./pages/AdminDashboard";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ProtectedRoute from "./utils/prorectedRoute";
import DashboardWithAccessControl from "./components/Dashboard";
import Account from "./components/Account";
import BotsWithAccessControl from "./components/Bots";
import TeamWithAccessControl from "./components/Team";
import API from "./components/API";
import ChatBotDetailsWithAccessControl from "./components/Bots_components/ChatBotDetails";
import LogDetails from "./components/Bots_components/LogDetails";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Analytics from "./components/Analytics";
import { Suspense, useEffect } from "react";
import ShareChatBot from "./pages/ShareChatBot";
import PackageModify from "./components/PackageModify";
import ThankYouPage from "./pages/ThankYou";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import EmailVerify from "./pages/EmailVerify";
import IntegartionsWithAccessControl from "./components/Integration";


function App() {

	const GoogleAuthClientID = process.env.REACT_APP_GOOGLEAUTH_CLIENTID
	const urlParams = queryString.parse(window.location.search);
	const { t, i18n } = useTranslation();
	const lang = navigator.language;
	// const lang = localStorage.getItem("language");

	// console.log("app.js");


	useEffect(() => {
		i18n.changeLanguage(urlParams.lang ? urlParams.lang : 'en');
		// localStorage.setItem("language", lang);
	}, [])


	return (
		<Suspense fallback="loading">
			<GoogleOAuthProvider clientId={GoogleAuthClientID}>
				<BrowserRouter>
					<Routes>

						<Route path="/" element={<Login />}></Route>

						{/* <Route path="/thankyou/" element={<ThankYouPage />}></Route> */}
						<Route path="/thankyou" element={<ProtectedRoute component={ThankYouPage} />}/>
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<SignUp />}></Route>
						<Route path="/forget-password" element={<ForgetPassword />}></Route>
						<Route path="/reset-password" element={<ResetPassword />}></Route>
						<Route path="/verify-email" element={<EmailVerify/>}></Route>
						<Route path="/share/:type/:teamid/:botid" element={<ShareChatBot />}></Route>
						<Route
							path="/admin-dashboard"
							element={
								<ProtectedRoute
									component={AdminDashboard}
								/>
							}>

							<Route index element={<DashboardWithAccessControl />} />
							<Route path="dashboard" element={<DashboardWithAccessControl />} />
							<Route path="account" element={<Account />} />
							<Route path="chatbots" element={<BotsWithAccessControl />} />
							<Route path="integrations" element={<IntegartionsWithAccessControl />} />
							<Route path="chatbots/:chatbotid" element={<ChatBotDetailsWithAccessControl />} />
							<Route path="chatbots/:chatbotid/log" element={<LogDetails />} />
							<Route path="team" element={<TeamWithAccessControl />} />
							<Route path="api" element={<API />} />
							<Route path="user-management" element={<Analytics />} />
							<Route path="package" element={<PackageModify />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</GoogleOAuthProvider>
		</Suspense>
	);
}

export default App;
