import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import analyticsService from "../utils/analyticsService";

const ThankYouPage = () => {
  const [checkoutSession, setCheckoutSession] = useState();
  const [rewardfulData, setRewardfulData] = useState();
  const navigate = useNavigate();
  const email = JSON.parse(localStorage.getItem("docsbot-users"))?.email;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // You can now access specific query parameters like this:
  const session_id = queryParams.get("session_id");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const mode = process.env.NODE_ENV;

  const fetch_session_details = async () => {
    try {
      const res = await fetch(
        BASE_URL + `/session_status?session_id=${session_id}`
      );
      const session_data = await res.json();

      console.log(session_data);
      if (session_data) {
        setCheckoutSession(session_data);
      }
    } catch (error) {
      console.error("failed to fetch session Data", error);
    }
  };
  const fetch_rewarful_affaliation = async () => {
    const apiSecret = "7ed5a497053b109d60ca8fca24ae173b"; // Replace with your actual API secret
    const apiUrl = `https://api.getrewardful.com/v1/referrals?stripe_customer_id=${checkoutSession?.session_details.customer}`;

    try {
      const res = await fetch(apiUrl, {
        method: "GET",
        headers: {
          // Basic authentication with API secret
          Authorization: `Basic ${btoa(`${apiSecret}:`)}`,
        },
      });
      const reward_data = await res.json();

      console.log(reward_data);
      if (reward_data.data.length > 0) {
        setRewardfulData(reward_data.data);
      }
    } catch (error) {
      console.error("failed to fetch referral Data", error);
    }
  };

  useEffect(() => {
    fetch_session_details();
    // fetch_rewarful_affaliation();

    const script = document.createElement("script");
    script.innerHTML = `
            rewardful('ready', function() { console.log('Rewardful Ready!') });
        `;
    document.body.appendChild(script);
    // console.log(script);
  }, []);

  useEffect(() => {
    // console.log("sessionData changed", checkoutSession);
    // if (checkoutSession?.session_details.customer) {
    //   console.log("fetching affaliation data");
    //   fetch_rewarful_affaliation();
    // }
    // adding purchasing event to gtm
    if (checkoutSession) {
      const product = checkoutSession.product_details;
      const invoice = checkoutSession.invoice_details;
      const item = invoice.lines.data[0];
      analyticsService.trackEventInProduction("purchase", {
        ecommerce: {
          transaction_id: invoice.id, //'T_12345'
          value: invoice.total, // 1603.43,
          tax: invoice.tax, // 4.90,
          currency: invoice.currency, //'USD',
          coupon: "", // 'SUMMER_SALE',
          items: [
            {
              item_id: item.id,
              item_name: product.name,
              affiliation: "", //'Happychat.ai',
              coupon: "", //'SUMMER_FUN',
              discount: invoice.discount,
              index: 0,
              item_category: item.plan.interval,
              price: item.amount,
            },
          ],
        },
      });
    }
  }, [checkoutSession]);

  useEffect(() => {
    // Add the Rewardful conversion tracking script
    const script = document.createElement("script");
    script.innerHTML = `
            rewardful('convert', { email: '${email}' });
        `;
    document.body.appendChild(script);
    // console.log(script);
    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [email]);

  useEffect(() => {
    // adding purchasing event to gtm
    //    analyticsService.trackEvent('purchase', {
    //     ecommerce: {
    //       transaction_id: 'T_12345',
    //       value: 1603.43,
    //       tax: 4.90,
    //       currency: 'USD',
    //       coupon: 'SUMMER_SALE',
    //       items: [
    //         {
    //           item_id: 'SKU_12345',
    //           item_name: 'Starter/Pro/Growth',
    //           affiliation: 'Happychat.ai',
    //           coupon: 'SUMMER_FUN',
    //           discount: 2.22,
    //           index: 0,
    //           item_category: 'Yearly/Monthly',
    //           price: 99.00,
    //         },
    //       ],
    //     },
    //   });
    console.log(mode);
  }, [rewardfulData]);

  return (
    <Flex
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={2}
    >
      <Box
        as={BsFillCheckCircleFill}
        size="74px"
        color="var(--Web-color-orange)"
        mb={4}
      />
      <Heading size="2xl" textAlign="center" mb={4}>
        Thank You!
      </Heading>
      <Text fontSize="m" textAlign="center" mb={4}>
        Your order was completed successfully. We sent an email to{" "}
        <Text as="span" fontWeight="700">
          {email}
        </Text>{" "}
        with your order confirmation and receipt.
      </Text>
      <Button
        onClick={() => navigate("/admin-dashboard/dashboard")}
        fontSize={"14px"}
        _hover={{ bg: "#f97913" }}
        bg={"var(--Web-color-orange)"}
        color="white"
        w={"151px"}
        h={"34px"}
        borderRadius={"65px"}
      >
        Go to dashboard
      </Button>
    </Flex>
  );
};

export default ThankYouPage;
