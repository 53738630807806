import React, { createContext, useEffect, useReducer, useState } from 'react';


// Define the initial state
const initialState = {
    globalTeam: {},
  };
  
  // Define the reducer function
  const reducer = (state, action) => {
    switch (action.type) {
      case 'Set_Global_Team':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

// Create a new context
export const AppContext = createContext();

// Create a provider component to wrap around your app
export const AppProvider = ({ children }) => {
  // Define your global state and its update function
  const [globalTeam, setGlobalTeam] = useReducer(reducer, {},()=>{
    const localData = localStorage.getItem('globalTeam');
    return localData? JSON.parse(localData):{};
  });
  
//   useEffect(() => {
//     // Retrieve the globalValue from localStorage on component mount
//     const storedTeam = localStorage.getItem('globalTeam');
//     if (storedTeam) {
//         console.log("first time context",JSON.parse(storedTeam));
//         setGlobalTeam(JSON.parse(storedTeam));
//     }
//   }, []);

  useEffect(() => {
    // Update localStorage whenever globalValue changes
    localStorage.setItem('globalTeam', JSON.stringify(globalTeam));
  }, [globalTeam]);

  // Create a context object to provide the global state and update function
  const contextValue = {
    globalTeam,
    setGlobalTeam,
  };



  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
