import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Progress,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import FetchWithToken from "../utils/FetchWithToken";
import i18next from "i18next";
import queryString from "query-string";
import analyticsService from "../utils/analyticsService";
import { MdOutlineMailOutline } from "react-icons/md";

const EmailVerify = ({}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const [verification, setverification] = useState(false);
  const [email, setemail] = useState(location.state?.email);
  const searchParams = new URLSearchParams(location.search);
  const emailToken = searchParams.get("verify_token");
  const userId = searchParams.get("user_id");
  const [urlParams, seturlParams] = useState(
    queryString.parse(window.location.search)
  );

  const handleResendVerificationEmail = (event) => {
    event.preventDefault();

    fetch(BASE_URL + "/verify_email", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((data) => {
        setemail("");
        if (data.message) {
          toast({
            title: data.message,
            status: "warning",
            duration: 4000,
            isClosable: true,
          });
        } else {
          toast({
            title: data.error,
            status: "warning",
            duration: 4000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        toast({
          title: "An error occurred. Please try again later.",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const handleEmailChange = (event) => {
    setemail(event.target.value);
  };

  useEffect(() => {
    if (emailToken && userId) {
      fetch(BASE_URL + "/verify_token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ verify_token: emailToken, user_id: userId }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.user_id) {
            setverification(true);
            localStorage.setItem("docsbot-users", JSON.stringify(data));
            var existingDataString = localStorage.getItem("docsbot-users");
            var existingData = JSON.parse(existingDataString);
            existingData.selectedTeam = data.team_details[0];
            var updatedDataString = JSON.stringify(existingData);
            localStorage.setItem("docsbot-users", updatedDataString);
            if (urlParams.lang) {
              // navigate(`/admin-dashboard/dashboard?lang=${urlParams.lang}`);
              navigate(`/admin-dashboard/chatbots?lang=${urlParams.lang}`);
            } else {
              // navigate(`/admin-dashboard/dashboard`);
              navigate(`/admin-dashboard/chatbots`);
            }
          } else {
            toast({
              title: data.error,
              status: "warning",
              duration: 4000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          toast({
            title: "An error occurred. Please try again later.",
            status: "warning",
            duration: 4000,
            isClosable: true,
          });
        });
    }
  }, [emailToken, userId]);

  useEffect(() => {
    seturlParams(queryString.parse(window.location.search));
    i18next.changeLanguage(urlParams.lang ? urlParams.lang : "en");
    // localStorage.setItem("language", lang);
  }, []);

  return (
    <Flex
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={4}
    >
      {emailToken && userId ? (
        <Box width={{ base: "100%", md: "40%" }}>
          {verification ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Box
                as={BsFillCheckCircleFill}
                size="74px"
                color="var(--Web-color-orange)"
                mb={4}
              />
              <Heading size="xl" textAlign="center" mb={4}>
                Email successfully verified.
              </Heading>
            </Box>
          ) : (
            <Box>
              <Progress colorScheme="orange" hasStripe isIndeterminate />
              <Heading size="xl" textAlign="center" mb={4}>
                Verifing your email
              </Heading>
            </Box>
          )}
        </Box>
      ) : (
        <Box width={{ base: "100%", md: "40%" }}>
          {/* <div style={{ width: 77.4, height: 70, position: "relative" }}> */}
            <Center
            mx={'auto'}
            mb={4}
            px
              style={{
                width: 77.4,
                height: 70,
                // transform: "rotate(180deg)",
                // transformOrigin: "0 0",
                background: "linear-gradient(#FED802 0%, #FF8321 100% )",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)",
                borderRadius: 14,
              }}
            >
                <MdOutlineMailOutline color="white" size={'35px'} />

            </Center>
           
          {/* </div> */}
          <Heading  fontWeight={700} fontSize={24} textAlign="center" mb={4}>
            Check Your Email
          </Heading>
          <Text
            fontSize="m"
            textAlign="center"
            mb={4}
            fontWeight={600}
            opacity={0.6}
          >
            {/* We have sent an email with a link that you will need to click to verify your email. You might need to check your spam folder if you don't see it. */}
            <Text as={"b"}>Account created successfully</Text> 🎉. Please check
            your email to confirm your account. You might need to check your
            spam folder if you don’t see it .
          </Text>
          <Text
            fontSize="m"
            color={"#000028"}
            opacity={0.6}
            textAlign="center"
            mb={4}
            fontWeight={500}
          >
            If you do not receive the email within the next 5 minutes, click the
            link below to resend verification email.
          </Text>
          <Text
            color={"#FF8321"}
            textAlign="center"
            fontSize={15}
            fontFamily={"Satoshi"}
            fontWeight={700}
            textTransform={"capitalize"}
            wordBreak={"break-word"}
            onClick={handleResendVerificationEmail}
            cursor={"pointer"}
            _hover={{ color: "#FF8300", textDecoration: "underline" }}
          >
            Resend verification email
          </Text>
          {/* <form onSubmit={handleResendVerificationEmail}>
                        <FormControl>
                            <FormLabel>Email address</FormLabel>
                            <Input required borderRadius={'20px'} type='email' bg={'gray.100'} value={email} onChange={handleEmailChange} placeholder="youremail@domain.com" />
                            <Button type="submit" _hover={{ bg: '#EB6F0D' }} color={'white'} bg={'#FF8321'} borderRadius={'20px'} mt={4} w={'100%'}>Resend verification email</Button>
                        </FormControl>
                    </form> */}
        </Box>
      )}
    </Flex>
  );
};

export default EmailVerify;
