import { Button } from "@chakra-ui/button";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/form-control";
import { Image } from "@chakra-ui/image";
import { Input } from "@chakra-ui/input";
import { Box, Flex, Heading, Stack, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import logoDark from '../assets/logoDark.png';

const ResetPassword = () => {
 const BASE_URL = process.env.REACT_APP_BASE_URL

  const location = useLocation();
  const toast = useToast()


  const searchParams = new URLSearchParams(location.search);
  const resetToken = searchParams.get("reset_token");
  const userId = searchParams.get("user_id");
  console.log(userId);
  const navigate =useNavigate()
  useEffect(() => {
    if(!resetToken){
        navigate('/login')
    }
}, []);
  
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const isPasswordInvalid = password.length > 0 && password.length < 8;
  const isConfirmPasswordInvalid = confirmPassword.length > 0 &&  confirmPassword !=password;
  const handleSubmit = (e) => {
    e.preventDefault();

    if (isPasswordInvalid) {
      return;
    }

    if (password !== confirmPassword) {
      return;
    }

    // Reset password API call or further processing
    fetch(BASE_URL+'/reset_password', {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ password: password,reset_token:resetToken, user_id:userId })
    })
        .then(response => response.json())
        .then(data => {
            if(data.message === "Password reset successfully" ){
                toast({
                    title: "Password reset successfully",
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                })
                navigate('/login');
            }else{
                toast({
                    title: data.message,
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                })
            }
           

        })
        .catch(err =>{ 
            toast({
                title: "An error occurred. Please try again later.",
                status: 'warning',
                duration: 4000,
                isClosable: true,
            })
            console.log(err)});
  };

  return (
    <Flex minHeight="100vh" width="full" align="center">
    <Box width="full" align="center">
         <Image
             src={logoDark}
             alt='logodark'
             h={'40px'}
         />
        <Box my={4} p={8} maxWidth="450px" borderWidth={1} borderRadius={10} boxShadow="lg">
            {/* <Image src="https://docsbot.ai/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdocsbot-logo.c6071a27.png&w=256&q=75" alt="Logo" mb={4} /> */}
            {/* <Image
             src={logoDark}
             alt='logodark'
             h={'40px'}
         /> */}
          <Heading mb={2} as="h2" size="md" mt={2}>
            Reset Password
          </Heading>
     
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={isPasswordInvalid}>
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {isPasswordInvalid && (
                <FormErrorMessage  mt={0}>
                  Password should be at least 8 characters long
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={isConfirmPasswordInvalid}>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {isConfirmPasswordInvalid && (
                <FormErrorMessage mt={0}>
                 Password did not match
                </FormErrorMessage>
              )}
            </FormControl>
            <Button
               _hover={{bg:'#EB6F0D'}} color={'white'} bg={'#FF8321'}
              type="submit"
              disabled={isPasswordInvalid || password !== confirmPassword}
            //   isLoading={isLoading}
            //   loadingText="Resetting..."
            >
              Reset Password
            </Button>
          </Stack>
        </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default ResetPassword;
