import { Box, Button, Heading, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { useRef } from "react";
import { RiLinksLine } from "react-icons/ri";
import { RxCopy } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const ShareAPI = ({ isOpen, onClose, botData }) => {

    const BASE_URL = process.env.REACT_APP_BASE_URL
    const team_id = JSON.parse(localStorage.getItem("docsbot-users")).selectedTeam.team_id;
    const navigate = useNavigate();
    const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;

    const botInputRef = useRef(null);
    const teamInputRef = useRef(null);

    const handleBotCopy = () => {
        if (botInputRef.current) {
            botInputRef.current.select();
            document.execCommand('copy');
        }
    };

    const handleTeamCopy = () => {
        if (teamInputRef.current) {
            teamInputRef.current.select();
            document.execCommand('copy');
        }
    };

    return (
        <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody p={0}>
                    <Box p={'30px 25px'} bg={'rgba(255, 131, 33, 0.05)'}>
                        <Heading fontSize={'30px'} >Share Links</Heading>
                        <Text >Share public links for others to interact with your chatbot, if it’s set to public</Text>
                        <Box display={'flex'} gap={2} mt={'21px'}>
                            <Button
                                _hover={{ border: "1px solid var(--Web-color-orange)", color: "var(--Web-color-orange)" }}
                                variant="outline"
                                borderRadius={65}
                                border={"1px solid #000000"}
                                color={'#000000'}
                                leftIcon={<RiLinksLine size={16} />}
                                p={"6px 15px"}
                                fontSize={"14px"}
                                w={'117px'}
                                h={'34px'}
                                fontWeight={600}
                                onClick={() => window.open(`/share/chat/${user_id}/${botData?.bot_id}`, '_blank')}
                            >
                                Chat Page
                            </Button>
                            <Button
                                _hover={{ border: "1px solid var(--Web-color-orange)", color: "var(--Web-color-orange)" }}
                                variant="outline"
                                borderRadius={65}
                                border={"1px solid #000000"}
                                color={'#000000'}
                                leftIcon={<RiLinksLine size={16} />}
                                p={"6px 15px"}
                                fontSize={"14px"}
                                w={'117px'}
                                h={'34px'}
                                fontWeight={600}
                                onClick={() => window.open(`/share/question_answering/${user_id}/${botData?.bot_id}`, '_blank')}
                            >
                                Q/A Page
                            </Button>
                        </Box>
                    </Box>
                    <Box p={'30px 25px'}>
                        <Box pb={'45px'}>

                            <Heading fontSize={'30px'}>API Documentation</Heading>
                            <Text mt={'7px'}>Access the complete{' '}
                                <Text as="span" color={' var(--Web-color-orange)'} textDecoration="underline" cursor={'pointer'} onClick={() => window.location.href = '/docs.html'}>
                                    HappyChat AI API documentation here.
                                </Text>
                                {' '}Utilize the Team ID and Bot ID provided below for the admin API and chat APIs specific to this chatbot.</Text>
                        </Box>
                        <Box p={2}>

                            <Heading fontSize={'30px'}>Team ID</Heading>
                            <InputGroup size='md' mt={'7px'} >
                                <Input
                                    pr='4.5rem'
                                    placeholder='Team ID'
                                    value={team_id}
                                    readOnly
                                    ref={teamInputRef}
                                />
                                <InputRightElement width='4.5rem'>
                                    <Button onClick={handleTeamCopy} _hover={{ bg: "#EB6F0D" }} fontSize={'12px'} bg={'#FF8321'} color={'white'} h='1.75rem' size='sm' leftIcon={<RxCopy />} mr={2}>
                                        Copy
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                        <Box p={2}>

                            <Heading fontSize={'30px'}>Bot ID</Heading>
                            <InputGroup size='md' mt={'7px'} >
                                <Input
                                    pr='4.5rem'
                                    ty
                                    placeholder='Bot Id'
                                    value={botData?.bot_id}
                                    readOnly
                                    ref={botInputRef}
                                />
                                <InputRightElement width='4.5rem'>
                                    <Button onClick={handleBotCopy} _hover={{ bg: "#EB6F0D" }} fontSize={'12px'} bg={'#FF8321'} color={'white'} h='1.75rem' size='sm' leftIcon={<RxCopy />} mr={2}>
                                        Copy
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </Box>
                </ModalBody>

            </ModalContent>
        </Modal>


    );
}


export default ShareAPI;